import { ErrorMessage } from "../../comp/ErrorMessage";
import { ListUseItems } from "../../comp/ListItems";
import { Modal } from "../../comp/Modal";
import { searchInFields } from "../../extends/string";
import { UseItems } from "../../hooks/useItems";
import { useModal } from "../../hooks/useModal";
import { IContest } from "../../model/models";
import { IPresentation } from "../../model/pptx";
import { IDataQuery } from "../../model/query";
import { IDocumentHeader } from "../../model/xlsx";
import DocService from "../../services/DocService";
import strings from "../../strings";
import { CreatePresentationForm } from "../EditPresentationPage/forms/CreatePresentationForm";
import { TocDataLoader } from "../EditPresentationPage/forms/TocDataLoader";
import { DocumentRow } from "./DocumentsView";

interface IPresentationsTabViewProps {
    title?: string
    search?: string
    contest: IContest
    useItems: UseItems<IDocumentHeader>
}

export function PresentationsTabView({ search, contest, useItems }: IPresentationsTabViewProps) {
    const createPresentation = useModal<IPresentation>()

    function createPresentationHandler() {
        createPresentation.openModal({ id: "", name: contest?.title, contestId: contest.id } as IPresentation)
    }
    function submitPresentationHandler(presentation: IPresentation) {
        createPresentation.closeModal()
        let created = Date.now()
        useItems.addItem({
            id: presentation.id, 
            name: presentation.name, 
            description: presentation.description,
            created, lastEdit: created
        } as IDocumentHeader)
    }
    async function deletePresentationHandler(item: IDocumentHeader) {
        try {
            const resp = await DocService.deletePresentation(item.id)
            if (resp.statusText === "OK")
                useItems.removeItem(item)
        } catch (e) {
            console.error(e)
        }
    }
    async function clonePresentationHandler(item: IDocumentHeader) {
        try {
            let name = global.prompt(strings.prompt_enter_title, item.name)
            if (!name)
                return
            name = name.trim()
            if (useItems.items.find(item => item.name === name)) {
                global.alert(`Презентация с имененм "${name}" уже существует`)
                return
            }
            let data = {
                id: "", name, contestId: item.contestId,
                created: Date.now(),
                query: {} as IDataQuery,
                slides: [], templates: []
            } as IPresentation
            const resp = await DocService.clonePresentation(item.id, data)
            if (resp.statusText === "OK")
                useItems.addItem(resp.data, true)
        } catch (e) {
            console.error(e)
        }
    }
    return <>
        <div className="pt-4 pb-4 flex flex-row justify-end gap-2">
            {/* <button className="textbutton" disabled
                onClick={createImagesZipHandler}>{strings.mi_download_arch}</button> */}
            <button className="textbutton"
                onClick={createPresentationHandler}>{strings.button_create_presentation}</button>
            {/* <button className="textbutton"
                onClick={createTableHandler}>{strings.button_create_table}</button> */}
            {/* <button className="textbutton" 
                onClick={createSlideTemplateHandler}>{strings.button_create_slide_template}</button> */}
        </div>

        <ListUseItems
            search={search}
            useItems={useItems}
            onFilter={(items, s) =>
                items.filter(m => searchInFields(s, m.name, m.description))
            }
            render={item =>
                <DocumentRow key={item.id}
                    item={item} isPresentation
                    onDelete={deletePresentationHandler}
                    onClone={clonePresentationHandler} />
            }
            nobottom />
            {(contest && createPresentation.modal) &&
                <Modal title="Создать презентацию"
                    onClose={createPresentation.closeModal}
                    wide>
                        <TocDataLoader contestId={contest.id} 
                            onDone={(toc) =>
                                <CreatePresentationForm
                                    presentation={createPresentation.modal!}
                                    toc={toc}
                                    onClose={createPresentation.closeModal}
                                    onSave={submitPresentationHandler} />
                            }
                            onError={() => 
                                <>
                                <ErrorMessage message={strings.err_load_data} />
                                <button className="errorbutton" 
                                    onClick={createPresentation.closeModal}>{strings.button_close}</button>
                                </>
                            }
                            />
                </Modal>
            }

    </>;
}
