import { AxiosError } from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { DescrView } from "../../comp/DescrView"
import { EditImageField } from "../../comp/fields/EditImageField"
import { FileUploader } from "../../comp/FileUploader"
import { CreateContest } from "../../comp/forms/CreateContest"
import { CreateStageForm } from "../../comp/forms/CreateStageForm"
import { Label, LabelWith } from "../../comp/Label"
import { ListItems, ListItemsReorder } from "../../comp/ListItems"
import { BottomLogoView } from "../../comp/LogoView"
import { Modal } from "../../comp/Modal"
import { StageRow } from "../../comp/rows/StageRow"
import { TextField } from "../../comp/TextField"
import { useModal } from "../../hooks/useModal"
import { IAttachmentFile, IContest, IStage, IUser, MAX_NOMINATION_COUNT, dbid } from "../../model/models"
import paths from "../../paths"
import DataService from "../../services/DataService"
import strings from "../../strings"
import { AttachmentFileRow } from "../ContestEntityPage"
import { ContestTemplatesPage } from "./ContestTemplatesPage"
import { useStaticPages } from "../../hooks/useItems"

// const PRELIMINARY_STAGE = { 
//     title: "Предварительная стадия",
//     description: ""
// } as IStage

interface IContestDescrPageProps {
    contest: IContest
    contestId: dbid
    setContest: (nextContest: IContest) => void
}

interface ICanEditIf {
    buttonIcon?: string
    buttonOnClick?: () => void
}

function editIf(contest: IContest, handler: () => void): ICanEditIf {
    if (contest.isAdmin)
        return { buttonIcon: "edit", buttonOnClick: handler }
    else
        return {}
}

export function ContestDescrPage({ contest, contestId, setContest }: IContestDescrPageProps) {
    const navigate = useNavigate()
    const [error, setError] = useState<string>()
    const editModal = useModal<IContest>()
    const stageModal = useModal<IStage>()
    const staticPageModal = useModal<string>()

    async function sendFile(files: File[]) {
        var nextAttachments = [...contest.attachments || []]
        for (let file of files) {
            const resp = await DataService.uploadDoc(file, contestId)
            if (resp.statusText === "OK") {
                nextAttachments = [...nextAttachments, resp.data]
            }
        }
        setContest({ ...contest, attachments: nextAttachments })
    }
    async function deleteDocHandler(file: IAttachmentFile) {
        if (!contest.isAdmin || !global.confirm(strings.confirm_delete_doc))
            return
        try {
            const resp = await DataService.deleteDoc(file.filename, contestId)
            if (resp.statusText === "OK") {
                setContest({
                    ...contest,
                    attachments: (contest?.attachments || []).filter(m => m.filename !== file.filename)
                })
            }
        } catch (e) {
            console.error(e)
        }

    }

    async function togglePublishedHandler() {
        if (!contest || !contest.isAdmin)
            return
        if (!global.confirm(contest.isPublished ? strings.action_unpublish : strings.action_publish + "?"))
            return
        try {
            const data = { isPublished: !contest.isPublished } as IContest
            const resp = await DataService.saveContest(contest.id, data)
            setContest(resp.data)
        } catch (e) {
            console.error(e)
            const err = e as AxiosError
            setError(err.message || "Can't delete contest!")
        }
    }
    async function toggleOpenSubmitHandler() {
        if (!contest || !contest.isAdmin)
            return
        const isOpenSubmit = contest.isOpenSubmit === true
        if (!global.confirm(isOpenSubmit ? strings.action_close_submit : strings.action_open_submit + "?"))
            return

        try {
            const data = { isOpenSubmit: !isOpenSubmit } as IContest
            const resp = await DataService.saveContest(contest.id, data)
            setContest(resp.data)
        } catch (e) {
            console.error(e)
            const err = e as AxiosError
            setError(err.message || "Can't delete contest!")
        }
    }

    function saveHandler(nextContest: IContest) {
        setContest(nextContest)
        editModal.closeModal()
    }
    async function updateStages(nextStages: IStage[]) {
        try {
            const resp = await DataService.updateStages(contestId, nextStages)
            setContest(resp.data)
        } catch (e) {
            console.error(e)
        }
    }
    function reorderStagesHandler(nextStages: IStage[]) {
        if (global.confirm("Сохранить новый порядок стадий конкурса?"))
            updateStages(nextStages)
    }
    async function saveStageHandler(nextStage: IStage) {
        stageModal.closeModal()
        if (nextStage.id) {
            const nextStages = contest.stages.map(stage => stage.id === nextStage.id ? nextStage : stage)
            updateStages(nextStages)
        } else {
            try {
                const resp = await DataService.createStage(contestId, nextStage)
                let nextStages = contest.stages ? [...contest.stages, resp.data] : [resp.data]
                let nextContest = { ...contest, stages: nextStages } as IContest
                setContest(nextContest)
            } catch (e) {
                console.error(e)
            }
        }
    }
    async function saveStaticPageId(next: string | undefined) {
        try {
            const data = { staticPageId: next } as IContest
            const resp = await DataService.saveContest(contest.id, data)
            setContest(resp.data)
            staticPageModal.closeModal()
        } catch (e) {
            console.error(e)
            const err = e as AxiosError
            setError(err.message || "Can't delete contest!")
        }
    }

    function deleteStageHandler(deleteStage: IStage) {
        if (!global.confirm("Удалить стадию конкурса?"))
            return
        updateStages(contest.stages.filter(stage => stage.id !== deleteStage.id))
    }

    async function uploadLogo(nextLogo: string) {
        if (!contest) {
            return console.error("Empty contextId")
        }
        try {
            const data = { ...contest, logo: nextLogo } as IContest
            const resp = await DataService.saveContest(data.id, data)
            setContest(resp.data)
        } catch (e) {
            console.error(e)
        }
    }

    async function deleteHandler() {
        if (global.confirm(strings.confirm_delete_contest)) {
            try {
                setError("")
                const resp = await DataService.deleteContest(contestId)
                if (resp.statusText === 'OK') {
                    navigate(paths.dashboard)
                } else {
                    setError(resp.data?.message || "Can't delete contest!")
                }
            } catch (e) {
                console.error(e)
                const err = e as AxiosError
                setError(err.message || "Can't delete contest!")
            }
        }
    }
    async function setReplyToEmailHandler() {
        var nextReplyToEmail = prompt("Изменить адрес электронной почты для обсуждения заявок", contest.replyToEmail || "")
        if (nextReplyToEmail == null) {
            return;
        }
        nextReplyToEmail = nextReplyToEmail.trim();
        try {
            contest.replyToEmail = nextReplyToEmail.length === 0 ? undefined : nextReplyToEmail;
            await DataService.saveContest(contest.id, contest);
            setContest({ ...contest });
        } catch (e) {

        }
    }

    async function setMaxNominations(e: React.ChangeEvent<HTMLSelectElement>) {
        const n = Number.parseInt(e.currentTarget.value)
        if (!n || n < 1) {
            return
        }
        if (!global.confirm("Сохранить изменение?")) {
            return
        }
        try {
            contest.maxNominations = n
            const resp = await DataService.saveContest(contest.id, contest)
            if (resp.statusText === "OK") {
                setContest({ ...contest })
            }
        } catch (e) {

        }
    }
    async function setTitleHandler() {
        const title = global.prompt("Введите новое название конкурса", contest.title)
        if (!title) {
            return
        }
        try {
            contest.title = title
            const resp = await DataService.saveContest(contest.id, contest)
            if (resp.statusText === "OK") {
                setContest({ ...contest })
            }
        } catch (e) {

        }
    }
    async function setDescriptionHandler() {
        const description = global.prompt("Введите новое описание конкурса", contest.description)
        if (description == null) {
            return
        }
        try {
            contest.description = description
            const resp = await DataService.saveContest(contest.id, contest)
            if (resp.statusText === "OK") {
                setContest({ ...contest })
            }
        } catch (e) {

        }
    }
    async function setEmailHandler() {
        if (contest.email) {
            if (global.confirm("Удалить email адрес для примема заявок?")) {
                try {
                    const resp = await DataService.deleteEmail(contest.id)
                    if (resp.statusText === "OK") {
                        setContest({ ...contest, email: undefined })
                    }
                } catch (e) {

                }
            }
        } else {
            const email = global.prompt("Введите желаемый email адрес для примема заявок. Длиной более 4 символов содержащий только цифры, латинские буквы или символы \"-\",\"_\"\".\"",
                contest.email || ""
            )
            if (email) {
                try {
                    const emailFixed = email.trim().toLowerCase()
                    const resp = await DataService.setEmail(contest.id, emailFixed)
                    if (resp.statusText === "OK") {
                        setContest({ ...contest, email: emailFixed })
                    }
                } catch (e) {

                }
            }
        }
    }
    return (
        <>
            <div className="flex md:flex-row flex-col gap-2">
                <EditImageField label={strings.lab_logo}
                    className="md:flex-1"
                    contestId={contestId}
                    value={contest.logo}
                    onChange={uploadLogo}
                />
                <div className="md:flex-1 flex flex-col">
                    <TextField label={strings.lab_title} text={contest.title}
                        {...editIf(contest, setTitleHandler)}
                    />

                    <LabelWith text={strings.lab_descr}>
                        <DescrView className="line-clamp-10 textfield" body={contest.description}
                            {...editIf(contest, setDescriptionHandler)}
                        />
                    </LabelWith>

                    <TextField className="flex-auto0"
                        label={strings.lab_contest_email}
                        text={contest.email ? contest.email + "@cityawards.ru" : undefined}
                        emptyPlaceholder={strings.msg_missing}
                        {...editIf(contest, setEmailHandler)}
                    />
                    <TextField className="flex-auto0"
                        label={strings.lab_reply_to_email}
                        text={contest.replyToEmail}
                        emptyPlaceholder={strings.msg_missing}
                        {...editIf(contest, setReplyToEmailHandler)}
                    />
                    <LabelWith className="flex-auto0"
                        text={strings.lab_max_nominations}>
                        {contest.isAdmin
                            ? <select className="textfield"
                                value={contest.maxNominations || MAX_NOMINATION_COUNT}
                                onChange={setMaxNominations}
                            >
                                {!contest.maxNominations &&
                                    <option value={MAX_NOMINATION_COUNT} key={0}>{strings.msg_default_max_nominations}</option>
                                }
                                {Array.from(Array(10).keys()).map(n =>
                                    <option value={n + 1} key={n + 1}>{n + 1}</option>
                                )}
                            </select>
                            : <span className="textfield">{contest.maxNominations || strings.msg_default_max_nominations}</span>}
                    </LabelWith>
                    <TextField className="flex-auto0"
                        label={strings.lab_static_page}
                        text={contest.staticPageId}
                        emptyPlaceholder={strings.msg_missing}
                        {...editIf(contest, () => staticPageModal.openModal(contest.staticPageId || ""))}
                    />
                    {/* <LabelWith className="flex-auto0"
                        text={strings.lab_static_page}>
                        {contest.isAdmin
                            ? <select className="textfield"
                                value={contest.maxNominations || MAX_NOMINATION_COUNT}
                                onChange={setMaxNominations}
                            >
                                {!contest.maxNominations &&
                                    <option value={MAX_NOMINATION_COUNT} key={0}>{strings.msg_default_max_nominations}</option>
                                }
                                {Array.from(Array(10).keys()).map(n =>
                                    <option value={n + 1} key={n + 1}>{n + 1}</option>
                                )}
                            </select>
                            : <span className="textfield">{contest.maxNominations || strings.msg_default_max_nominations}</span>}
                    </LabelWith> */}

                    <LabelWith text={strings.lab_user_status}>
                        {contest.isAdmin
                            ? <div className="textfield">{strings.lab_user_status_admin}</div>
                            : contest.isParticipating
                                ? <div className="textfield">{strings.lab_user_status_contestant}</div>
                                : <div className="textfield">{strings.lab_user_status_none}</div>
                        }
                    </LabelWith>
                </div>
            </div>

            {contest.isAdmin &&
                <LabelWith text={strings.lab_actions}>
                    <div className="flex flex-wrap gap-2 pt-2 justify-end">
                        {/* <button className="textbutton" onClick={() => navigate(paths.edit_presentation(contestId))}>
                        {strings.nav_presentation}
                    </button> */}
                        <button className="textbutton" onClick={() => editModal.openModal(contest)}>
                            {strings.mi_edit}
                        </button>
                        <button className="greenbutton" onClick={togglePublishedHandler} >
                            {contest.isPublished ? strings.action_unpublish : strings.action_publish}
                        </button>
                        <button className="greenbutton" onClick={toggleOpenSubmitHandler} >
                            {contest.isOpenSubmit ? strings.action_close_submit : strings.action_open_submit}
                        </button>
                        <button className="redbutton" onClick={deleteHandler}>
                            {strings.mi_delete}
                        </button>
                    </div>
                </LabelWith>
            }
            <LabelWith text={strings.lab_attachments}>
                <FileUploader sendFile={contest.isAdmin ? sendFile : undefined}>
                    <ListItems items={contest.attachments || []}
                        render={m =>
                            <AttachmentFileRow key={m.filename} file={m} allowCopy
                                onDelete={contest.isAdmin ? deleteDocHandler : undefined} />
                        } />
                </FileUploader>
            </LabelWith>
            {contest.isAdmin &&
                <>
                    <LabelWith text={strings.lab_stages} className="py-big ">
                        {contest.isAdmin &&
                            <div className="flex flex-row justify-end">
                                <button className="textbutton"
                                    onClick={() => stageModal.openModal({} as IStage)}
                                >{strings.button_add_stage}</button>
                            </div>
                        }
                        <ListItemsReorder items={contest.stages}
                            onReorder={reorderStagesHandler}
                            getKey={item => item.id}
                            render={(stage, index) =>
                                <StageRow key={stage.id}
                                    stage={stage} index={index}
                                    onClick={() => stageModal.openModal(stage)}
                                    onDelete={() => deleteStageHandler(stage)} />
                            } />
                    </LabelWith>
                    <Label text="Шаблоны для сообщений по электронной почте" />
                    <div className="pl-4">
                        <ContestTemplatesPage
                            contest={contest}
                            setContest={setContest} />
                    </div>
                </>
            }

            {editModal.modal &&
                <Modal title={strings.dlg_title_edit_contest} onClose={editModal.closeModal}>
                    <CreateContest contest={editModal.modal}
                        onSave={saveHandler}
                        onCancel={editModal.closeModal}
                    />
                </Modal>
            }
            {stageModal.modal &&
                <Modal title="" onClose={stageModal.closeModal}>
                    <CreateStageForm stage={stageModal.modal}
                        onSave={saveStageHandler}
                        onCancel={stageModal.closeModal}
                    />
                </Modal>
            }
            {typeof staticPageModal.modal === "string" &&
                <Modal title="Выбирите страницу конкурса" onClose={staticPageModal.closeModal}>
                    <EditStaticPage contestId={contestId}
                        initValue={staticPageModal.modal}
                        onSave={saveStaticPageId}
                        onCancel={staticPageModal.closeModal}
                    />
                </Modal>
            }
            <BottomLogoView />
        </>
    )
}


interface IEditStaticPageProps {
    contestId: dbid
    initValue?: string
    onSave: (next: string | undefined) => void
    onCancel: () => void
}

function EditStaticPage({ contestId, initValue, onCancel, onSave }: IEditStaticPageProps) {
    const [value, setValue] = useState<string>(() => initValue || "")
    const pages = useStaticPages(contestId)
    return (
        <div className="flex flex-col gap-y-4">
            <LabelWith text={strings.lab_static_page}>
                <select className="textfield"
                    value={value} onChange={e => setValue(e.target.value)}>
                    <option value={""}></option>
                    {pages.items.map(e =>
                        <option key={e.id} value={e.uri || e.id}>{e.title} (/{e.uri || e.id})</option>
                    )}
                </select>
            </LabelWith>
            <div className="flex flex-row justify-end gap-x-2">
                <button className="textbutton" onClick={onCancel}>{strings.button_cancel}</button>
                <button className="greenbutton" onClick={() => onSave(value || undefined)}>{strings.button_save}</button>
            </div>

        </div>
    )
}