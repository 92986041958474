import { MAX_SPAN } from "../comp/docs/models"
import strings from "../strings"
import { IBorder, IPadding, ImageScale } from "./common"
import { IContestUser, ITemplate, IVoteResult, TemplateFieldType, dbid } from "./models"
import { ISlideImageField, ISlideTextField } from "./pptx"
import { IDataIDs, IDataQuery, IDataScope, IJuryVoteResult, IVoteData, TYPE_CONTEST, TYPE_ENTITY, TYPE_HEADER, TYPE_NOMINATION, TocData, scopes } from "./query"
import { buildTemplateString } from "./templates"

/**
 * Задача законфигурировать:
 * - Все фотографии из галереи
 * - Имена жюри
 * - Результаты голосования жюри
 */
export interface IDocCellsTemplate {
    expr: string
    keys?: string[]
}

export interface IDocCell {
    rowKey: string
    col: number
    span?: number
    border?: IBorder
    padding?: IPadding

    text?: ISlideTextField
    image?: ISlideImageField
    templ?: IDocCellsTemplate
    //
}

export interface IDocRow {
    key: string
    index: number
    columns: IDocCell[]
    template?: string
    depends?: IDataIDs
    types?: string[]
    height?: number
}

export interface IXlsxTemplates {
    [index: string]: IDocRow | undefined
}

export interface IXlsxColumnWidths {
    [index: number]: number | undefined
}

export interface IXlsxDoc {
    id: dbid
    contestId: dbid
    name: string
    description?: string
    created: number
    lastEdit?: number
    vote?: boolean
    voteStageId?: dbid
    header?: boolean
    query: IDataQuery

    maxColumn: number
    rows: IDocRow[]
    templates: IXlsxTemplates
    columnWidths?: IXlsxColumnWidths
}

export interface IDocumentHeader {
    id: dbid
    contestId: dbid
    name: string
    description?: string
    created: number
    lastEdit?: number
}

///
///
///

function headerRowTemplate(contestTemplate: ITemplate | undefined) {
    let columns: IDocCell[] = [
        { col: 0, rowKey: TYPE_HEADER, text: { text: "№" } },
        { col: 1, rowKey: TYPE_HEADER, text: { text: strings.lab_title } },
    ]

    if (contestTemplate) {
        let imageFld = contestTemplate?.fields?.find(fld => fld.type === TemplateFieldType.IMAGE)
        let galleryFld = contestTemplate?.fields?.find(fld => fld.type === TemplateFieldType.IMAGES)
        if (imageFld) {
            columns.push({
                col: columns.length, rowKey: TYPE_HEADER, text: { text: imageFld.label }
            })
        } else if (galleryFld) {
            columns.push({
                col: columns.length, rowKey: TYPE_HEADER, text: { text: galleryFld.label }
            })
        }
        contestTemplate.fields.forEach(fld => {
            if (fld.type === TemplateFieldType.TEXT || fld.type === TemplateFieldType.ENUM)
                columns.push({
                    col: columns.length, rowKey: TYPE_HEADER, text: { text: fld.label }
                })
        })
    }
    columns.push({
        col: columns.length, rowKey: TYPE_HEADER, text: { text: "Статус" }
    })

    return {
        key: TYPE_HEADER,
        index: 0,
        columns: columns,
        types: [TYPE_CONTEST]
    } as IDocRow
}

function nominationRowTemplate() {
    return {
        key: TYPE_NOMINATION,
        index: 0,
        columns: [
            { col: 0, rowKey: TYPE_NOMINATION, text: { text: "{number}) {nomination.title}" }, span: MAX_SPAN },
        ],
        types: [TYPE_CONTEST, TYPE_NOMINATION]
    } as IDocRow
}

function entityRowTemplate(contestTemplate: ITemplate | undefined, dependFromNomination: boolean) {
    let columns: IDocCell[] = [
        { col: 0, rowKey: TYPE_ENTITY, text: { text: "{number}" } },
        { col: 1, rowKey: TYPE_ENTITY, text: { text: "{entity.title}" } },
    ]

    if (contestTemplate) {
        let imageFld = contestTemplate?.fields?.find(fld => fld.type === TemplateFieldType.IMAGE)
        let galleryFld = contestTemplate?.fields?.find(fld => fld.type === TemplateFieldType.IMAGES)
        if (imageFld) {
            columns.push({
                col: columns.length, rowKey: TYPE_ENTITY, image: { src: `{entity.fields.${imageFld.name}}`, scale: ImageScale.cover }
            })
        } else if (galleryFld) {
            columns.push({
                col: columns.length, rowKey: TYPE_ENTITY, image: { src: `{entity.fields.${galleryFld.name}[0]}`, scale: ImageScale.cover }
            })
        }
        contestTemplate.fields.forEach(fld => {
            if (fld.type === TemplateFieldType.TEXT || fld.type === TemplateFieldType.ENUM)
                columns.push({
                    col: columns.length, rowKey: TYPE_ENTITY, text: { text: `{entity.fields.${fld.name}}` }
                })
        })
    }
    columns.push({
        col: columns.length, rowKey: TYPE_ENTITY, text: { text: "{stateShort}" }
    })    

    return {
        key: TYPE_ENTITY,
        index: 0,
        columns: columns,
        types: dependFromNomination ? [TYPE_CONTEST, TYPE_NOMINATION, TYPE_ENTITY] : [TYPE_CONTEST, TYPE_ENTITY]
    } as IDocRow
}

function buildCellFromTemplate(rowKey: string, template: IDocCell, toc: TocData, scope: IDataScope, depends?: IDataIDs) {
    return {
        col: template.col,
        rowKey: rowKey,
        span: template.span,
        border: template.border ? { ...template.border } : undefined,
        padding: template.padding ? { ...template.padding } : undefined,
        text: template.text ? { ...template.text, text: buildTemplateString(template.text.text, toc, scope, depends) } : undefined,
        image: template.image ? { ...template.image, src: buildTemplateString(template.image.src, toc, scope, depends) } : undefined,
    } as IDocCell
}

function buildRowFromTemplate(key: string, row: number, template: IDocRow, toc: TocData, scope: IDataScope, depends: IDataIDs | undefined,
    columnWidths: IXlsxColumnWidths | undefined
) {
    const columns: IDocCell[] = []
    for (let i = 0; i < template.columns.length; i++) {
        const colW = columnWidths && columnWidths[i];
        const templCell = template.columns[i]

        if (templCell.templ) {
            let o = toc.fetchExpr(templCell.templ.expr, scope, depends)
            if (o) {
                if (typeof o === "string")
                    o = o.split("\n")
                let keys = templCell.templ.keys || Object.keys(o)
                keys.forEach((k, index) => {
                    scope.item = o[k]
                    let cell = buildCellFromTemplate(key, { ...templCell, col: templCell.col + index }, toc, scope, depends)
                    columns.push(cell)
                    if (columnWidths) {
                        columnWidths[cell.col] = colW;
                    }        
                })
            } else {
                console.error("Broken templ expr: " + templCell.templ.expr, o)
            }
        } else {
            let cell = buildCellFromTemplate(key, templCell, toc, scope, depends)
            cell.col = columns.length;
            columns.push(cell)
            if (columnWidths) {
                columnWidths[cell.col] = colW;
            }
        }
    }

    return {
        key,
        index: row,
        columns,
        height: template.height,
        template: template.key,
        depends: depends
    } as IDocRow
}

export function buildRows(toc: TocData, query: IDataQuery, templates: IXlsxTemplates | undefined, voteData: IVoteData | undefined, 
    addHeader: boolean | undefined, tempColumnWidths: IXlsxColumnWidths | undefined
) {
    const headerTemplate = addHeader ? ((templates && templates[TYPE_HEADER]) || headerRowTemplate(toc.contest.template)) : undefined
    const nominationTemplate = (templates && templates[TYPE_NOMINATION]) || nominationRowTemplate()
    const entityTemplate = (templates && templates[TYPE_ENTITY]) || entityRowTemplate(toc.contest.template, true)

    const columnWidths = {...tempColumnWidths};

    let nominationsCount = 0
    let entitiesCount = 0
    const rows: IDocRow[] = []
    if (headerTemplate) {
        // headerTemplate.columns.forEach(col => {
        //     col.rowKey = TYPE_HEADER
        // })
        // headerTemplate.types = [TYPE_CONTEST]

        const headerScope = {
            votes: {} as IJuryVoteResult
        }
        voteData?.jury?.forEach(jury => {
            let scopeId = scopes.fromJuryId(jury.id)
            headerScope.votes[scopeId] = { name: jury.name }
        })
        const headerDepends = {
            contest: scopes.fromContestId(toc.contest.id),
        }
        let headerKey = "header"
        const row = buildRowFromTemplate(headerKey, rows.length, headerTemplate, toc, headerScope, headerDepends, columnWidths)
        // console.log("Header row", row);
        rows.push(row)
    }

    if (voteData)
        toc.setVoteResult(voteData)
    else
        toc.clearVoteResult()

    toc.filterQuery(query).forEach((sect, index) => {
        nominationsCount++
        const nomKey = index + ""

        let entities = sect.filterQuery(query)

        const nomScope = {
            index: (sect.nomination.index + 1) + "",
            number: index + 1 + "",
            count: entities.length,
            totalCount: sect.items.length,
            entities
        }
        const nomDepends = {
            contest: scopes.fromContestId(toc.contest.id),
            nomination: sect.nominationScope,
        }
        const row = buildRowFromTemplate(nomKey, rows.length, nominationTemplate, toc, nomScope, nomDepends, undefined)
        rows.push(row)

        entities.forEach((item, index) => {
            entitiesCount++
            let entityScope = {
                index: (item.index + 1) + "",
                number: index + 1 + "",
                totalVotes: voteData ? ((item.$votesValue || 0).toFixed(1) + "") : "",
                totalPoints: voteData ? ((item.$votesPoints || 0).toFixed(1) + "") : "",
                totalVotesCount: voteData ? ((item.$votesCount || 0) + "") : "",
                state: strings.entityStateToString(item.entity.state),
                stateShort: strings.entityStateToShortString(item.entity.state),
                votes: item.$voteResult
            }
            let entityDepends = {
                ...nomDepends,
                entity: item.scopeId,
            }
            // console.log("buildRowFromTemplate", entityScope, entityDepends)

            let row = buildRowFromTemplate(`${nomKey}-${index}`, rows.length, entityTemplate, toc, entityScope, entityDepends, undefined)
            rows.push(row)
        })
    })
    // console.log(`Build result: nominationsCount: ${nominationsCount}, entitiesCount: ${entitiesCount}`)
    return {
        rows, nominationsCount, entitiesCount,
        columnWidths, 
        templates: {
            [TYPE_HEADER]: headerTemplate,
            [TYPE_NOMINATION]: nominationTemplate,
            [TYPE_ENTITY]: entityTemplate
        }
    }
}

export function buildRows2(filename: string, toc: TocData, query: IDataQuery, templates: IXlsxTemplates | undefined, voteResult: IVoteResult[] | undefined) {
    const nominationTemplate = (templates && templates[TYPE_NOMINATION]) || nominationRowTemplate()
    const entityTemplate = (templates && templates[TYPE_ENTITY]) || entityRowTemplate(toc.contest.template, true)

    const rows: IDocRow[] = []// toc.build(filename, query, voteResult, (parent, scope, depends, index) => {
    //     let key = index + ""
    //     let template = //nominationTemplate || entityTemplate
    //     return buildRowFromTemplate(key, index, template, toc, scope, depends)
    // })


    return {
        rows,
        templates: {
            [TYPE_NOMINATION]: nominationTemplate,
            [TYPE_ENTITY]: entityTemplate
        }
    }
}

// export function rebuildRows(rows: IDocRow[], templates: IXlsxTemplates, toc: TocData) {
//     const res: IDocRow[] = []
//     rows.forEach((row, index) => {
//         if (row.template) {
//             let template = templates[row.template]
//             if (!template) {
//                 console.log(`Not found template ${row.template} for row #${index+1}`)
//                 return
//             }

//             res.push(
//                 buildRowFromTemplate(row.key, index, template, toc, row.depends)
//             )
//         } else {
//             res.push(row)
//         }
//     })
//     return res
// }

export function rowSpans(row: IDocRow): number {
    var res = 0
    for (let i = 0; i < row.columns.length; i++) {
        let cell = row.columns[i]
        let cellSpan = cell?.span || 1
        if (cellSpan === MAX_SPAN)
            res += 1
        else
            res += cellSpan
    }
    return res
}

export function calcMaxColumns(rows: IDocRow[], def: number = 5) {
    let maxCols = def
    rows.forEach(r =>
        maxCols = Math.max(maxCols, rowSpans(r))
    )
    return maxCols
}