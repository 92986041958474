import { useTitle } from '../hooks/useTitle';
import strings from '../strings';
import { AboutPage } from './AboutPage';

export function HomePage() {
    useTitle(strings.nav_main)
    // const publics = usePublicContests()
    return (
        <AboutPage />
        // <PublicStaticPage staticPageId='winter-ekb-2025'
        //     checkRegistrationPeriod />
    );
}