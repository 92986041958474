import { useState } from "react"
import { RESIZE_BOX_SIZE } from "../comp/ResizeBox"
import { ISlideField } from "../model/pptx"
import { IPoint } from "../model/common"

enum ResizeDir {
    start = "start", end = "end"
}
interface IResizeObj<T> {
    dir: ResizeDir
    field: T
}

function isLeftResize(x: number, item: ISlideField) {
    return x <= item.pos.x + RESIZE_BOX_SIZE
}
function isRightResize(x: number, item: ISlideField) {
    return x >= item.pos.x + item.size.x - RESIZE_BOX_SIZE
}
function isTopResize(y: number, item: ISlideField) {
    return y <= item.pos.y + RESIZE_BOX_SIZE
}
function isBottomResize(y: number, item: ISlideField) {
    return y >= item.pos.y + item.size.y - RESIZE_BOX_SIZE
}
function isCenter(x: number, pos: number, size: number) {
    const dpos = pos + size / 2
    const dsize = RESIZE_BOX_SIZE / 2
    return (x >= dpos - dsize) && (x <= dpos + dsize)
}

export function useDragAndResize(
    getByPos: (pt: IPoint) => ISlideField | undefined,
    setItem: (item: ISlideField) => void
) {
    const [selected, setSelected] = useState<ISlideField>()
    const [dragging, setDragging] = useState<ISlideField>()
    const [resizeX, setResizeX] = useState<IResizeObj<ISlideField>>()
    const [resizeY, setResizeY] = useState<IResizeObj<ISlideField>>()
    const [pos, setPos] = useState({ x: 0, y: 0 } as IPoint)
    const [size, setSize] = useState({ x: 0, y: 0 } as IPoint)
    const [pagePos, setPagePos] = useState({ x: 0, y: 0 } as IPoint)

    function onMouseDown(e: React.MouseEvent<HTMLDivElement>) {
        const x = e.pageX - e.currentTarget.offsetLeft;// - (e.currentTarget.parentElement?.offsetLeft || 0)
        const y = e.pageY - e.currentTarget.offsetTop;// - (e.currentTarget.parentElement?.offsetTop || 0)

        const curr = getByPos({ x, y })

        var resizingDirX: ResizeDir | undefined = undefined
        var resizingDirY: ResizeDir | undefined = undefined
        if (curr && selected) {
            if (isRightResize(x, curr) && isBottomResize(y, curr)) {
                // RightBottom
                resizingDirX = ResizeDir.end
                resizingDirY = ResizeDir.end
            } else if (isLeftResize(x, curr) && isTopResize(y, curr)) {
                // LeftTop
                resizingDirX = ResizeDir.start
                resizingDirY = ResizeDir.start
            } else if (isLeftResize(x, curr) && isBottomResize(y, curr)) {
                // LeftBottom
                resizingDirX = ResizeDir.start
                resizingDirY = ResizeDir.end
            } else if (isRightResize(x, curr) && isTopResize(y, curr)) {
                // RightTop
                resizingDirX = ResizeDir.end
                resizingDirY = ResizeDir.start
            } else if (isLeftResize(x, curr) && isCenter(y, curr.pos.y, curr.size.y)) {
                // Left
                resizingDirX = ResizeDir.start
            } else if (isRightResize(x, curr) && isCenter(y, curr.pos.y, curr.size.y)) {
                // Right
                resizingDirX = ResizeDir.end
            } else if (isCenter(x, curr.pos.x, curr.size.x) && isTopResize(y, curr)) {
                // Top
                resizingDirY = ResizeDir.start
            } else if (isCenter(x, curr.pos.x, curr.size.x) && isBottomResize(y, curr)) {
                // Bottom
                resizingDirY = ResizeDir.end
            }
        }

        if (resizingDirX || resizingDirY) {
            setDragging(undefined)
            if (curr && resizingDirX)
                setResizeX({ dir: resizingDirX, field: curr })
            else
                setResizeX(undefined)
            if (curr && resizingDirY)
                setResizeY({ dir: resizingDirY, field: curr })
            else
                setResizeY(undefined)
        } else {
            setDragging(curr)
            setResizeX(undefined)
            setResizeY(undefined)
        }
        setSelected(curr)
        // console.log("Set selected", curr)

        if (!curr)
            return

        setPos({ x: curr.pos.x, y: curr.pos.y })
        setSize({ x: curr.size.x, y: curr.size.y })

        setPagePos({ x: e.pageX, y: e.pageY })
        setItem(curr)
    }
    function onMouseUp(e: React.MouseEvent<HTMLDivElement>) {
        if (dragging) {
            setItem({ ...dragging, pos: { x: pos.x, y: pos.y } })
            setDragging(undefined)
        } else if (resizeX || resizeY) {
            const obj = resizeX?.field || resizeY?.field
            setItem({ ...obj!!, pos: { x: pos.x, y: pos.y }, size: { x: size.x, y: size.y } })
            setResizeX(undefined)
            setResizeY(undefined)
        }
    }
    function onMouseMove(e: React.MouseEvent<HTMLDivElement>) {
        const dx = e.pageX - pagePos.x
        const dy = e.pageY - pagePos.y
        if (dragging) {
            const newX = pos.x + dx
            const newY = pos.y + dy
            setPos({ x: newX, y: newY })
            setPagePos({ x: e.pageX, y: e.pageY })
        } else if (resizeX || resizeY) {
            let nextPos = { ...pos }
            let nextSize = { ...size }
            switch (resizeX?.dir) {
                case ResizeDir.start:
                    nextSize.x -= dx
                    nextPos.x += dx
                    break
                case ResizeDir.end:
                    nextSize.x += dx
                    break
            }
            switch (resizeY?.dir) {
                case ResizeDir.start:
                    nextPos.y += dy
                    nextSize.y -= dy
                    break
                case ResizeDir.end:
                    nextSize.y += dy
                    break
            }
            setSize(nextSize)
            setPos(nextPos)
            setPagePos({ x: e.pageX, y: e.pageY })
        }

    }
    return {
        selected, setSelected, dragging, resize: resizeX?.field || resizeY?.field, pos, size,
        onMouseDown, onMouseUp, onMouseMove
    }
}