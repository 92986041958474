import { useState } from "react";
import { AvatarView } from "../comp/AvatarView";
import { useAppDispatch, useTypedSelector } from "../hooks/useTypedSelector";
import { logoutAction, uploadAvatarAction } from "../store/actions/auth";
import { Loading } from "../comp/Loading";
import { PhotoSize } from "../model/models";
import { useContests, usePhotos } from "../hooks/useItems";
import { ImageEx } from "../comp/ImageFB";
import ContestRow from "../comp/rows/ContestRow";
import { LabelWith } from "../comp/Label";
import strings from "../strings";
import { ListUseItems } from "../comp/ListItems";
import { ImgUploader } from "../comp/ImgUploader";
import { useImageFullscreen } from "../comp/forms/ImageFullscreen";
import { useTitle } from "../hooks/useTitle";
import { ErrorMessage } from "../comp/ErrorMessage";

export function UserPage() {
    const dispatch = useAppDispatch()
    const state = useTypedSelector(state => state.auth);
    const [uploading, setUploading] = useState(false)
    const photos = usePhotos()    
    const contests = useContests()
    const imgFullscreen = useImageFullscreen()
    useTitle(strings.nav_profile + (state.user?.fullname || ""))

    async function sendAvatarFile(files: FileList) {//succ: () => void
        let file = files[0]
        if (!file)
            return
        setUploading(true)
        try {
            dispatch(uploadAvatarAction(file))
        } catch(e) {
            console.error(e)
        } finally {
            setUploading(false)
        }
    }

    function logoutHandler() {
        dispatch(logoutAction())
    }
    const user = state.user || state.jury
    if (!user) 
        return (
            <ErrorMessage message={strings.err_auth}/>
        )
    else 
        return (
            <div className="maincontainer flex flex-col w-full  space-y-4">       
                <div className="flex flex-col md:flex-row md:items-center gap-2 space-4">
                    <div className="relative flex flex-col justify-center items-center">
                        <LabelWith text={strings.lab_userid}>
                            <span className="debugfield">{user.id}</span>
                        </LabelWith>
                        <div className="rounded-lg bg-primary3 p-2 overflow-hidden">
                            <AvatarView src={user.avatar} size={PhotoSize.big}
                                onClick={() => user?.avatar && imgFullscreen.open([user.avatar, undefined])}
                                />
                            <ImgUploader onUpload={sendAvatarFile} className="absolute-center"/>
                        </div>

                        {uploading && <Loading/>}
                    </div>
                    <div className="flex flex-col space-y-2">
                        <span className="text-2xl">{user.fullname}</span>
                        <span className="">{user.email}</span>
                        <span>{user.juryContestId ? strings.lab_jury : strings.lab_user}</span>
                        <button className="textbutton" onClick={logoutHandler}>{strings.button_logout}</button>
                    </div>
                </div>

                <LabelWith text={strings.lab_contests}>
                    <ListUseItems useItems={contests}
                        render={item =>
                            <ContestRow key={item.id} item={item} />
                        }
                        />
                </LabelWith>

                <LabelWith text={strings.lab_photos}>
                    <div className="flex flex-row flex-wrap gap-2">
                        {photos.loading && <Loading/>}
                        {photos.items.map( img => 
                            <ImageEx key={img.orig} 
                                src={img.orig}
                                className="object-cover"
                                size={PhotoSize.small}
                                onClick={() => imgFullscreen.open([img.orig, photos.items.map(e => e.orig)])}
                                />
                        )}
                    </div>
                </LabelWith>
                {imgFullscreen.render()}
            </div>
        )
}
