import { useContext, useEffect, useMemo, useState } from "react";
import { ErrorMessage } from "../../../comp/ErrorMessage";
import { Icon } from "../../../comp/Icon";
import { extractImageSrc } from "../../../comp/ImageFB";
import { Loading } from "../../../comp/Loading";
import { IPoint, IRect, ImageAlign } from "../../../model/common";
import { PhotoSize } from "../../../model/models";
import { GalleryLayoutKind, ISlideField, ISlideGalleryField } from "../../../model/pptx";
import { ImagePos, buildRects } from "../../../services/PptxBuilder";
import { ImageFullscreen, OpenImageContext } from "../../../comp/forms/ImageFullscreen";
import { Modal } from "../../../comp/Modal";
import { useModal } from "../../../hooks/useModal";

interface SlideGalleryBoxProps {
    field: ISlideField
    data: ISlideGalleryField
    isTemplate?: boolean
}

export class ImgLoader {
    uri: string
    uriSrc: string
    loading: boolean = true
    fail: boolean = false
    ratio: number = 1
    img: HTMLImageElement

    pos: IRect = { x: 0, y: 0, w: 0, h: 0 }

    constructor(private src: string) {
        this.uriSrc = extractImageSrc(src, PhotoSize.orig)
        this.uri = extractImageSrc(src, PhotoSize.large)
        this.loading = true
        this.img = new Image()
        this.img.crossOrigin = '*'
    }

    preload(onpreload: (l: ImgLoader) => void) {
        // let promise = new Promise<>()
        let onload = () => {
            this.loading = false
            this.ratio = this.img.width / this.img.height
            onpreload(this)
        }
        let onerror = () => {
            if (this.uriSrc !== this.uri) {
                this.uri = this.uriSrc
                this.img.src = this.uri
                return
            }
            console.error("preload img fail " + this.uri)
            this.loading = false
            this.ratio = 1
            this.fail = true
            onpreload(this)
        }
        this.img.onload = onload
        this.img.onerror = onerror
        this.img.src = this.uri
    }

    isSquare() {
        return !this.isHorz() && !this.isVert()
    }
    isHorz() {
        return this.ratio > 1.1
    }
    isVert() {
        return this.ratio < 0.9
    }
}

export class VerticalLayout {
    items: ImgLoader[] = []

    pos: IRect = { x: 0, y: 0, w: 0, h: 0 }

    constructor() {

    }

    ratio(): number {
        if (this.items.length === 1)
            return this.items[0].ratio
        let res = 0
        this.items.forEach(img => 
            res += 1 / img.ratio
        )
        return 1 / res
    }
}

type LayoutPlace = ImgLoader | VerticalLayout

export function isInstanceOfVerticalLayout(layout: any): layout is VerticalLayout {
    return Array.isArray(layout.items)
}

export class AllImagesLoader {
    images: ImgLoader[]
    layout: LayoutPlace[]

    constructor(private items: string[]) {
        this.layout = []
        this.images = items.map(src => new ImgLoader(src))
    }

    pos(layouts: LayoutPlace[], pos: IPoint, size: IPoint, gap: number) {
        if (layouts.length <= 1) {
            return
        }

        let gap2 = (gap * (layouts.length - 1)) / layouts.length
        let first = layouts[0]
        let wFirst, wOther: number
        if (isInstanceOfVerticalLayout(first)) {
            wFirst = size.x / 3 - gap2
            wOther = (size.x - wFirst) / (layouts.length - 1) - gap2
        } else if (!isInstanceOfVerticalLayout(first) && first.isHorz() && layouts.length == 2) {
            // w-2/3
            wFirst = 2*size.x / 3 - gap2
            wOther = size.x / 3 - gap2
        } else if (!isInstanceOfVerticalLayout(first) && first.isHorz()) {
            // w-1/2
            wFirst = size.x / 2 - gap2
            wOther = (size.x - wFirst) / (layouts.length - 1) - gap2
        } else {
            wFirst = size.x / layouts.length - gap2
            wOther = wFirst
        }

        let index = 0
        let x = pos.x
        for (let layout of layouts) {
            let w = index === 0 ? wFirst : wOther
            let y = pos.y
            if (isInstanceOfVerticalLayout(layout)) {
                let gap3 = (gap * (layout.items.length - 1)) / layout.items.length
                let h = (size.y / layout.items.length) - gap3
                layout.items.forEach( img => {
                    img.pos = { x, y, w, h }
                    y += h + gap
                })
            } else {
                layout.pos = { x, y, w, h: size.y }
            }
            x += w + gap
            index++
        }
    }

    preloadAsync() {
        // console.log("preloadAsync", this.images)
        let promise = new Promise<LayoutPlace[]>((resolve, reject) => {
            let onloadimg = () => {
                let loading = this.images.filter(img => img.loading).length
                if (!loading) {
                    let images = this.images.filter(img => !img.fail)
                    let first: ImgLoader | undefined = images[0]
                    if (first) {
                        this.layout.push(first)
                        let column = new VerticalLayout()
                        for(let i = 1; i < images.length; i++) {
                            let img = images[i]
                            if (!img.fail) {
                                if (img.isHorz() || img.isSquare()) {
                                    // if (column.length >= 3) {
                                    //     column = []
                                    // }
                                    if (column.items.length === 0) {
                                        this.layout.push(column)
                                    }
                                    column.items.push(img)
                                } else {//if (img.isVert() || img.isSquare()) {
                                    // column = new VerticalLayout()
                                    this.layout.push(img)
                                }
                            } else {
                                console.error("<Img load fail for ", img.uri)
                            }
                        }
                        // console.log(this.layout)
                        if (!first.isVert() && column.items.length === 1 && this.layout.length >= 4) {
                            this.layout.splice(0, 1)
                            column.items.push(first)
                        } else if (this.layout.length >= 4 && !first.isVert()) {
                            this.layout.splice(3)
                        } else if (this.layout.length > 4) {
                            // Не больше 4 в ряду
                            this.layout.splice(4)
                        } 
                        if (column.items.length > 2 && column.ratio() < 0.45) {
                            if (column.items.length >= 4) {
                                let column2 = new VerticalLayout()
                                column2.items = column.items.splice(2)
                                this.layout.push(column2)
                            } else {
                                // Не больше 3 в колонке
                                column.items.splice(3)
                            }
                        }
                    }
                    resolve(this.layout)
                }
            }
            this.images.forEach(img => 
                img.preload(onloadimg)
            )
        })
        return promise
    }
}

interface SlideGalleryBoxLayoutProps {
    items: string[]
    gap: number
    max?: number
}

function SlideGalleryBoxAuto({ items, gap, max }: SlideGalleryBoxLayoutProps) {
    const [loading, setLoading] = useState<boolean>()
    const images = useMemo<AllImagesLoader>(() => {
        // console.log("Start loading auto gallery for", items)
        setLoading(true)
        let loader = new AllImagesLoader(items.slice(0, max || 5))
        // loader.preload(() => setLoading(false))
        loader.preloadAsync().finally(() => setLoading(false))
        return loader
    }, [items])
     
    return (
        <div className="w-full h-full flex flex-row justify-center"
            style={{gap: gap}}>
            {loading 
            ? <Loading/> 
            : <>
            {images.layout.map( (layout, index) => 
                isInstanceOfVerticalLayout(layout) 
                ? <div key={layout.items[0]?.uri} 
                    className={`flex flex-col flex-auto ${index === 0 ? "w-1/3" : ""}`} style={{gap: gap}}>
                  {layout.items.map(img => 
                  <GalleryImage key={img.uri} 
                    src={img.uri} className="flex-auto" ratio={img.ratio}/>
                  )}  
                </div>
                : <GalleryImage key={layout.uri}
                    src={layout.uri} className={(layout.isHorz() && index === 0 && images.layout.length <= 2) ? "w-2/3" : (layout.isHorz() && index === 0) ? "flex-auto w-1/2" : "flex-auto"}
                    ratio={layout.ratio}/>
            )}
            </>
            }
        </div>
    )
}

function SlideGalleryBigCenterColRight({ items, gap, max }: SlideGalleryBoxLayoutProps) {
    let cols = items.slice(1, 4)
    return (
        <div className="w-full h-full flex flex-row justify-center"
            style={{gap: gap}}>
            <GalleryImage className={(cols.length > 0) ? "w-2/3" : "flex-auto"} src={items[0]}/>
            {cols.length > 0 && 
            <div className="flex flex-col w-1/3 "
                style={{gap: gap}}>
                {cols.map((src, index) => 
                <GalleryImage key={src || index} className="h-1/2" src={src}/>
                )}
            </div>
            }
        </div>
    )
}

const MOCKDATA = ["", "", ""]

export function SlideGalleryBox({ field, data, isTemplate }: SlideGalleryBoxProps) {
    const gap = data.gap || 1
    const [ poses, setPoses ] = useState<ImagePos[]>()
    const [ loading, setLoading ] = useState(() => true)
    const openImageContext = useContext(OpenImageContext)

    async function build() {
        setLoading(true)
        try {
            let poses = await buildRects({ pos: { x: 0, y: 0 }, size: field.size, key: "" }, data)
            // console.log(poses)
            setPoses(poses)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        !isTemplate && build()
    }, [data])

    if (isTemplate) {
        return <SlideGalleryBoxOld field={field} data={data} isTemplate={isTemplate}/>
    }
    function imageClickHandler(e: React.MouseEvent, src: string) {
        if (!openImageContext) {
            return
        }
        e.preventDefault()
        e.stopPropagation()
        openImageContext(src, data.items.trim().split("\n"))
    //     // alert("Image: " + src)
    //     fullscreen.openModal(src)
    }

    return (
        <div className="w-full h-full flex flex-row justify-center relative" style={{gap: gap}}>
            {loading 
            ? <Loading/>
            : poses
            ? poses.map((pos, index) => 
                <div className="absolute" key={pos.src + index}
                    onClick={e => imageClickHandler(e, pos.src)}
                    style={{
                        left: pos.pos.x + "px", 
                        top: pos.pos.y + "px",
                        width: pos.size.x + "px",
                        height: pos.size.y + "px",
                        background: `url(${pos.src})`,
                        backgroundPosition: ImageAlign.center,
                        // backgroundSize: "contain",
                        backgroundSize: poses.length == 1 ? "contain" : "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundClip: "content-box"            
                    }}>
                </div>
            )
            : <ErrorMessage message="Ошибка"/>
            }
        </div>
    )
}

function SlideGalleryBoxOld({ data, isTemplate }: SlideGalleryBoxProps) {
    const gap = data.gap || 1
    const items = useMemo(() => 
        (isTemplate || !data.items) ? MOCKDATA : data.items.split("\n")
    , [data])
    switch (data.kind) {
        case GalleryLayoutKind.AUTO: 
            if (isTemplate)
                return <SlideGalleryBigCenterColRight items={items} gap={gap}/>
            else 
                return <SlideGalleryBoxAuto items={items} gap={gap}/>

        case GalleryLayoutKind.BIG_CENTER_COL_RIGHT: 
            return <SlideGalleryBigCenterColRight items={items} gap={gap}/>
        
        case GalleryLayoutKind.BIG_CENTER_COL_LEFT: {
            let cols = items.slice(1, 4)
            return (
                <div className="w-full h-full flex flex-row justify-center"
                    style={{gap: gap}}>
                    {cols.length > 0 && 
                    <div className="flex flex-col w-1/3 "
                        style={{gap: gap}}>
                        {cols.map((src, index) => 
                        <GalleryImage key={src || index} className="h-1/2" src={src}/>
                        )}
                    </div>
                    }
                    <GalleryImage className={(cols.length > 0) ? "w-2/3" : "flex-auto"} src={items[0]}/>
                </div>
            )
        }
        case GalleryLayoutKind.GRID:
            return (
                <div className="w-full h-full grid grid-cols-2"
                    style={{gap: gap}}>
                    {items.map( (src, index) => 
                    <GalleryImage key={src || index} src={src}/>
                    )}
                </div>
            )
        case GalleryLayoutKind.ROW:
            return (
                <div className="w-full h-full flex flex-row"
                    style={{gap: gap}}>
                    {items.map( (src, index) => 
                    <GalleryImage key={src || index} className="flex-auto" src={src}/>
                    )}
                </div>
            )
        case GalleryLayoutKind.COL:
            return (
                <div className="w-full h-full flex flex-col"
                    style={{gap: gap}}>
                    {items.map( (src, index) => 
                    <GalleryImage key={src || index} className="flex-auto" src={src}/>
                    )}
                </div>
            )
        default:
            return (
                <div>
                    <div className="w-full h-full bg-error/50"></div>
                    <Icon name="image" className="absolute-center" size="20px"/>
                </div>
            )
    }
}
function GalleryImage({ src, className, ratio } : {  src?: string, className?: string, ratio?: number }) {
    if (src) {
        var imageUri = extractImageSrc(src, PhotoSize.large)
        return (
            <div className={className}
                style={{
                    background: `url(${imageUri})`,
                    backgroundPosition: ImageAlign.center,
                    // backgroundSize: "contain",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundClip: "content-box"            
                }}>
            </div>
        )
    } else 
        return (
            <div className={"flex flex-row justify-center items-center bg-error/50 " + (className || "")}>
                <Icon name="image" className="" size="20px"/>
            </div>
        )
}