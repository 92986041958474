import { useState } from "react"
import { toast } from "react-toastify"
import { Checkbox, GroupBox } from "../../comp/Checkbox"
import { IContextMenuItem } from "../../comp/ContextMenu"
import { Label, LabelWith } from "../../comp/Label"
import { Loading } from "../../comp/Loading"
import { Modal } from "../../comp/Modal"
import { MAX_SPAN } from "../../comp/docs/models"
import { useModal } from "../../hooks/useModal"
import { ImageScale } from "../../model/common"
import { DependsDataContext } from "../../model/depends"
import { IVoteResult, dbid } from "../../model/models"
import { IDataQuery, IVoteData, TocData } from "../../model/query"
import { IDocCell, IDocRow, IXlsxColumnWidths, IXlsxDoc, IXlsxTemplates, buildRows, calcMaxColumns } from "../../model/xlsx"
import { buildXlsx } from "../../services/DocBuilder"
import DocService from "../../services/DocService"
import strings from "../../strings"
import { DataQueryTocView } from "./DataQueryView"
import { DEF_COLUMN_WIDTH, DEF_ROW_HEIGHT, DocTablePages } from "./DocTable"
import { EditCellForm } from "./forms/EditCellForm"

const MENU_COLUMN_DEL = "del_col"
const MENU_COLUMN_ADD_LEFT = "add_col_left"
const MENU_COLUMN_ADD_RIGHT = "add_col_right"
const MENU_CELL_MERGE = "merge_cells"
const MENU_CELL_MERGE_MAX = "merge_max_cells"
const MENU_CELL_BREAK = "break_cells"
const MENU_CELL_BREAK_ALL = "break_all_cells"
const MENU_CELL_TO_IMAGE = "cell_to_image"
const MENU_CELL_TO_TEXT = "cell_to_text"

const MENU_COLUMN = [
    { id: MENU_COLUMN_DEL, title: "Удалить колонку" } as IContextMenuItem,
    { id: MENU_COLUMN_ADD_LEFT, title: "Добавить колонку слева" } as IContextMenuItem,
    { id: MENU_COLUMN_ADD_RIGHT, title: "Добавить колонку справа" } as IContextMenuItem,
]
const MENU_ROW = [
    { id: "test1", title: "Test ROW menu" } as IContextMenuItem
]
const MENU_CELL = [
    { id: MENU_CELL_MERGE, title: "Объединить со следующей" } as IContextMenuItem,
    { id: MENU_CELL_MERGE_MAX, title: "Объединить до края" } as IContextMenuItem,
    { id: MENU_CELL_BREAK, title: "Отделить правую" } as IContextMenuItem,
    { id: MENU_CELL_BREAK_ALL, title: "Разделить все" } as IContextMenuItem,
    // { id: MENU_CELL_TO_IMAGE, title: "Вставить картинку" } as IContextMenuItem,
    // { id: MENU_CELL_TO_TEXT,  title: "Вставить текст" } as IContextMenuItem,
]

function buildTemplateRows(templates: IXlsxTemplates) {
    const nextRows: IDocRow[] = []
    Object.values(templates).forEach(m => {
        if (m)
            nextRows.push(m)
    })
    return { rows: nextRows, templates }
}
function buildTemplateObj(rows: IEditRows) {
    if (rows.rows.length === 0)
        return
    let templates = {} as IXlsxTemplates
    rows.rows.forEach(rw =>
        templates[rw.key] = rw
    )
    return templates
}

function buildDoc(doc: IXlsxDoc, docRows: IEditRows, templRows: IEditRows) {
    return {
        ...doc,
        rows: docRows.rows,
        maxColumn: docRows.maxColumn,
        columnWidths: docRows.columnWidths,
        templates: buildTemplateObj(templRows)
    } as IXlsxDoc
}

interface DocumentEditorProps {
    document: IXlsxDoc
    toc: TocData
    voteData?: IVoteData
    onDeleted?: (docId: dbid, contestId: dbid) => void
    onChanged?: (doc: IXlsxDoc) => void
}

export function DocumentEditor({ document, toc, voteData, onDeleted, onChanged }: DocumentEditorProps) {
    const [saving, setSaving] = useState<boolean>()
    const [query, setQuery] = useState(document.query)
    const [addHeader, setAddHeader] = useState(document.header)
    const [editTemplate, setEditTemplate] = useState(false)
    const [docRows, setDocRows] = useState<IEditRows>(() => document)
    const [templRows, setTemplRows] = useState<IEditRows>(() => {
        return {
            rows: buildTemplateRows(document.templates).rows,
            columnWidths: document.columnWidths,
            maxColumn: document.maxColumn
        }
    })

    function setBuildRes(next: { rows: IDocRow[], templates: IXlsxTemplates, columnWidths?: IXlsxColumnWidths }) {
        let nextMaxColumn = calcMaxColumns(next.rows)
        setDocRows({ ...docRows, rows: next.rows, maxColumn: nextMaxColumn, columnWidths: next.columnWidths })
        setTemplRows({ ...templRows, rows: buildTemplateRows(next.templates).rows })
    }

    async function rebuildHandler() {
        let templates = buildTemplateObj(templRows)
        let next = buildRows(toc, query, templates, voteData, document.header, templRows.columnWidths);
        // next.columnWidths
        docRows.columnWidths = next.columnWidths || templRows.columnWidths;
        console.log(docRows.columnWidths);
        setBuildRes(next)
    }
    async function saveHandler() {
        setSaving(true)
        // console.log(docRows.columnWidths)
        const nextDoc = buildDoc(document, docRows, templRows)
        onChanged && onChanged(nextDoc)
        try {
            await DocService.updateTable(nextDoc)
            toast("Документ успешно сохранен")
        } catch (e) {
            console.error(e)
        } finally {
            setSaving(false)
        }
    }
    async function downloadHandler() {
        setSaving(true)
        await buildXlsx(document.name, docRows.rows, docRows.maxColumn, docRows.columnWidths)
        setSaving(false)
    }
    async function deleteHandler() {
        setSaving(true)
        try {
            const resp = await DocService.deleteTable(document.id)
            if (resp.statusText === "OK") {
                onDeleted && onDeleted(document.id, document.contestId)
            }
        } catch (e) {
            console.error(e)
        } finally {
            setSaving(false)
        }
    }
    function changeQueryHandler(nextQuery: IDataQuery) {
        setQuery(nextQuery)
        document.query = nextQuery
        let templates = buildTemplateObj(templRows)
        let next = buildRows(toc, nextQuery, templates, voteData, document.header, templRows.columnWidths)
        setBuildRes(next)
    }

    function changeRowsHandler(next: IEditRows) {
        // console.log("Change rows ", next)
        if (editTemplate) {
            setTemplRows(next)
        } else {
            setDocRows(next)
        }
    }
    function addVoteHandler(_: React.MouseEvent, next: boolean) {
        onChanged && onChanged({ ...document, vote: next })
    }
    function voteStageHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        onChanged && onChanged({ ...document, voteStageId: e.target.value ? e.target.value : undefined })
    }
    function addHeaderChangeHandler(_: React.MouseEvent, nextHeader: boolean) {
        // onChanged && onChanged({...document, header: next })
        document.header = nextHeader
        let templates = buildTemplateObj(templRows)
        let next = buildRows(toc, query, templates, voteData, nextHeader, templRows.columnWidths)
        setBuildRes(next)
    }

    return (
        <div className="flex flex-col">
            <div className="maincontainer">
                <Label text={strings.lab_query} />

                <DataQueryTocView query={query} toc={toc} addVote={!!voteData}
                    onChange={changeQueryHandler} />

                <GroupBox text={strings.lab_settings} innerClassName="pb-4">
                    <span>Allow empty nomination</span>

                    <Checkbox text="Строку заголовок"
                        value={addHeader === true} setValue={setAddHeader}
                        onChange={addHeaderChangeHandler}
                        left />

                    <div className="flex flex-row justify-between items-center">
                        <Checkbox text="Данные голосования" left disabled={!onChanged}
                            value={document.vote || false} setValue={() => { }}
                            onChange={addVoteHandler} />
                        <span className="text-xs text-disabled">{voteData ? `${voteData.result.length} проголосовавших членов жюри (${voteData.jury.length})` : "нет данных о голосовании"}</span>
                    </div>
                    <select className="textfield mx-4" disabled={!document.vote}
                        value={document.voteStageId || ""}
                        onChange={voteStageHandler}
                    >
                        <option key={""} value={""}></option>
                        {toc.contest.stages.map(stg =>
                            <option key={stg.id} value={stg.id}>{stg.title}</option>
                        )}
                    </select>
                </GroupBox>

                <Label text={strings.lab_actions} />
                <div className="flex flex-row justify-center gap-2">
                    <button className="greenbutton" onClick={downloadHandler}>
                        {strings.mi_download}
                    </button>
                    <button className="redbutton" onClick={deleteHandler}>
                        {strings.mi_delete}
                    </button>
                    {/* <button className="greenbutton" onClick={saveHandler}>
                        Обновить данные
                    </button> */}
                    {!!document.templates &&
                        <button className="greenbutton" onClick={rebuildHandler}>
                            {strings.button_rebuild}
                        </button>
                    }
                    <button className="greenbutton" onClick={saveHandler}>
                        {strings.button_save_changes}
                    </button>

                    {saving &&
                        <Loading text={strings.msg_saving} className="absolute-y-center left-0" />
                    }
                </div>

                <div className="flex flex-row pt-4 font-thin0">Кол-во строк: {docRows.rows.length}</div>

                <div className="flex flex-row justify-center gap-2 pt-2">
                    <button className="textbutton" disabled={!editTemplate} onClick={() => setEditTemplate(false)}>Документ</button>
                    <button className="textbutton" disabled={editTemplate} onClick={() => setEditTemplate(true)}>Шаблон</button>
                </div>
                <Label text={strings.lab_document} />
            </div>
            <DocTableEditor key={editTemplate ? "template" : "document"}
                documentName={document.name}
                toc={toc} voteData={voteData}
                doc={editTemplate ? templRows : docRows}
                editTemplate={editTemplate}
                onChange={changeRowsHandler}
            />
        </div>
    )
}

interface IEditRows {
    rows: IDocRow[]
    maxColumn: number
    columnWidths?: IXlsxColumnWidths
}

interface DocTableEditorProps {
    documentName: string
    doc: IEditRows,
    toc: TocData
    voteData?: IVoteData
    editTemplate: boolean
    onChange: (doc: IEditRows) => void
    pageSize?: number
}

export function DocTableEditor({ documentName, doc, toc, voteData, editTemplate, onChange }: DocTableEditorProps) {
    const editCellModal = useModal<[IDocCell, DependsDataContext, IDocRow]>()

    function setRows(next: IDocRow[]) {
        // console.log("Change rows", doc.columnWidths)
        onChange({ ...doc, rows: next })
    }
    function setMaxColumn(next: number) {
        onChange({ ...doc, maxColumn: next })
    }
    function confirmResetTemplate(row: IDocRow) {
        if (row?.template) {
            if (!global.confirm("Это строка создана из шаблона. Удалить привязку к шаблону?"))
                return false
            row.template = undefined
            setRows([...doc.rows])
        }
        return true
    }
    function resizeColumnHandler(column: number, width: number) {
        let columnWidths = doc.columnWidths || {}
        columnWidths[column] = width
        doc.columnWidths = columnWidths
        // console.log("Resizing column ", columnWidths)
    }
    function updateCellHandler(nextCell: IDocCell) {
        const row = doc.rows.find(row => row.key === nextCell.rowKey)
        if (!row)
            return
        row.columns = row.columns.map(cell => cell.col === nextCell.col ? nextCell : cell)
        setRows([...doc.rows])
    }

    function editCellHandler(cell: IDocCell) {
        let row = doc.rows.find(row => row.key === cell.rowKey)
        if (!row)
            return
        let context = new DependsDataContext(documentName, toc)
        if (row?.depends) {
            context.depends(row.depends)
            if (voteData)
                context.dependsVoteResult(voteData, !!row.depends.entity)
        } else if (row?.types) {
            context.dependsType(row.types)
            if (voteData)
                context.dependsVoteResult(voteData, row.types.indexOf("entity") >= 0)
        } else if (voteData) {
            context.dependsVoteResult(voteData, false)
        }
        // console.log(row, context, voteResult)
        editCellModal.openModal([cell, context, row])
    }
    function addColumn(col: number) {
        const nextRows = doc.rows.map(row => {
            let lastCell = row.columns[col] || row.columns[row.columns.length - 1]
            if (lastCell?.span !== MAX_SPAN) {
                let cell = { rowKey: row.key, col: col, text: { text: "" } } as IDocCell
                row.columns = [
                    ...row.columns.slice(0, col),
                    cell,
                    ...row.columns.slice(col).map((cell, index) => {
                        cell.col = col + 1 + index
                        return cell
                    })
                ]
            }
            return row
        })
        setRows(nextRows)
        setMaxColumn(calcMaxColumns(nextRows))
        return nextRows
    }
    function columnMenuClick(mi: IContextMenuItem, col: number) {
        switch (mi.id) {
            case MENU_COLUMN_DEL:
                if (!global.confirm("Удалить колонку с данными?"))
                    return
                const nextRows = doc.rows.map(row => {
                    // row.columns.splice(col, 1)
                    row.columns = [
                        ...row.columns.slice(0, col),
                        ...row.columns.slice(col + 1).map((cell, index) => {
                            cell.col = col + index
                            return cell
                        })
                    ]
                    return row
                })
                setRows(nextRows)
                setMaxColumn(calcMaxColumns(nextRows))
                break;
            case MENU_COLUMN_ADD_LEFT:
                addColumn(col)
                break;
            case MENU_COLUMN_ADD_RIGHT:
                addColumn(col + 1)
                break;
            default:
                break;
        }
    }
    function rowMenuClick(mi: IContextMenuItem, row: IDocRow) {

    }
    function cellMenuClick(mi: IContextMenuItem, cell: IDocCell) {
        let nextRows = doc.rows
        let row = nextRows.find(row => row.key === cell.rowKey)
        if (!row) {
            global.alert("Ошибка неизвестная строка!" + cell.rowKey)
            return
        }
        if (!confirmResetTemplate(row))
            return
        // console.log("cellMenuClick", cell)
        switch (mi.id) {
            case MENU_CELL_BREAK:
                if (cell.span === MAX_SPAN) {
                    cell.span = doc.maxColumn - cell.col - 1
                    row.columns.push({ rowKey: row.key, col: row.columns.length })
                    setRows([...nextRows])
                    setMaxColumn(calcMaxColumns(nextRows))
                } else if (cell.span) {
                    cell.span--
                    if (cell.span <= 1)
                        cell.span = undefined
                    row.columns.push({ rowKey: row.key, col: row.columns.length })
                    setRows([...nextRows])
                    setMaxColumn(calcMaxColumns(nextRows))
                }
                break;
            case MENU_CELL_BREAK_ALL:
                cell.span = undefined
                let cols = 0
                for (let i = 0; i < doc.maxColumn; i++) {
                    let cell2 = row.columns[i]
                    if (cell2)
                        cols += cell2.span || 1
                }
                for (let i = cols; i < doc.maxColumn; i++) {
                    row.columns.push({ rowKey: row.key, col: row.columns.length })
                }
                setRows([...nextRows])
                setMaxColumn(calcMaxColumns(nextRows))
                break;
            case MENU_CELL_MERGE_MAX:
                cell.span = MAX_SPAN
                row.columns.splice(cell.col + 1)
                setRows([...nextRows])
                setMaxColumn(calcMaxColumns(nextRows))
                break
            case MENU_CELL_MERGE:
                if (row.columns.indexOf(cell) === row.columns.length - 1) {
                    if (!global.confirm("Добавить колонку?"))
                        return
                    nextRows = addColumn(cell.col + 1)
                }
                let span = 1
                if (row) {
                    let nextCell = row.columns[cell.col + 1]
                    if (nextCell && (nextCell.image?.src || nextCell.text?.text))
                        return global.alert("Соседняя ячейка не пустая!")
                    let removedCell = row.columns.splice(cell.col + 1, 1)[0]
                    if (removedCell && removedCell.span)
                        span += removedCell.span - 1
                }
                cell.span = (cell.span || 1) + span
                setRows([...nextRows])
                setMaxColumn(calcMaxColumns(nextRows))
                break;
            case MENU_CELL_TO_TEXT:
                cell.image = undefined
                cell.text = { text: "" }
                setRows([...nextRows])
                editCellHandler(cell)
                break;
            case MENU_CELL_TO_IMAGE:
                cell.image = { src: "", scale: ImageScale.cover }
                cell.text = undefined
                setRows([...nextRows])
                editCellHandler(cell)
                break;
        }
    }
    function cellMenuDisabled(mi: IContextMenuItem, cell: IDocCell) {
        switch (mi.id) {
            case MENU_CELL_MERGE_MAX:
            case MENU_CELL_MERGE:
                return cell.span === MAX_SPAN

            case MENU_CELL_BREAK:
            case MENU_CELL_BREAK_ALL:
                return !cell.span
        }
        return false
    }
    function cellMenuVisible(mi: IContextMenuItem, cell: IDocCell) {
        switch (mi.id) {
            case MENU_CELL_TO_IMAGE:
                return !cell.image
            case MENU_CELL_TO_TEXT:
                return !cell.text
        }
        return true
    }
    // console.log("Recompose Editor")
    return (
        <>
            <DocTablePages rows={doc.rows}
                maxColumn={doc.maxColumn}
                columnWidths={doc.columnWidths}
                colMenu={[MENU_COLUMN, columnMenuClick]}
                rowMenu={[MENU_ROW, rowMenuClick]}
                cellMenu={[MENU_CELL, cellMenuClick, cellMenuDisabled, cellMenuVisible]}
                onColResize={resizeColumnHandler}
                onCellDblClick={editCellHandler}
            />
            {editCellModal.modal &&
                <Modal title="" onClose={editCellModal.closeModal}>
                    {editCellModal.modal &&
                        <EditCellForm size={{
                            x: (doc.columnWidths && doc.columnWidths[editCellModal.modal[0].col]) || DEF_COLUMN_WIDTH,
                            y: editCellModal.modal[2].height || DEF_ROW_HEIGHT
                        }}
                            cell={editCellModal.modal[0]}
                            context={editCellModal.modal[1]}
                            isTemplate={editTemplate}
                            onSubmit={updateCellHandler}
                            // onDelete={deleteField}
                            onClose={editCellModal.closeModal} />
                    }
                </Modal>
            }
        </>
    )
}