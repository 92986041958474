import { createContext, useState } from "react";
import { IUseModal, useModal } from "../../hooks/useModal";
import { PhotoSize } from "../../model/models";
import strings from "../../strings";
import { IconButton } from "../Icon";
import { ImageEx } from "../ImageFB";
import { Modal } from "../Modal";

// export interface IOpenImageData {
//     src: string
//     items?: string[]
// }
export type OpenImageDataCall = (src: string, items?: string[]) => void

export const OpenImageContext = createContext<OpenImageDataCall | undefined>(undefined)

interface IImageFullscreenProps {
    // modal: IUseModal<string>
    image: string
    items?: string[]
    onClose: () => void
}

export function ImageFullscreen({ image, items, onClose }: IImageFullscreenProps) {
    const [size, setSize] = useState(PhotoSize.large)
    const [current, setCurrent] = useState(image)

    return (
        <Modal title={strings.dlg_title_fullscreen_photo} 
            onClose={ onClose }
            fullscreen={true}>
            <div className="flex flex-col w-full h-full">
                <ImageEx src={current} size={size} alt={strings.dlg_title_fullscreen_photo}
                    objectFit={"contain"}
                    />
                <div className="flex flex-row gap-2 absolute right-4 bottom-4">
                    <IconButton transparent 
                        className=""
                        name="photo_size_select_large"
                        onClick={() => setSize(size === PhotoSize.large ? PhotoSize.orig : PhotoSize.large)}/>
                </div>
                {items && 
                <div className="flex flex-row overflow-auto justify-center gap-2 mt-2">
                    {items.map(e => 
                        <div key={e} className="aspect-square overflow-hidden p-2 hover:bg-white/25" onClick={() => setCurrent(e)}>
                            <ImageEx src={e} size={PhotoSize.small} className="w-[64px] h-[64px] rounded"
                                objectFit="cover"
                            />
                        </div>
                    )}
                </div>
                }
            </div>
        </Modal>
    )
}

// export function ImageFullscreenModal({ image, items, onClose }: IImageFullscreenProps) {
//     const [size, setSize] = useState(PhotoSize.large)
//     // if (modal.modal) {
//     //     const img = modal.modal
//     return (
//         <Modal title={strings.dlg_title_fullscreen_photo} 
//             onClose={ onClose }
//             fullscreen={true}>
//             <ImageEx src={image} size={size} alt={strings.dlg_title_fullscreen_photo}
//                 objectFit={"contain"}
//                 />
//             <div className="flex flex-row gap-2 absolute right-4 bottom-4">
//                 <IconButton transparent 
//                     className=""
//                     name="photo_size_select_large"
//                     onClick={() => setSize(size === PhotoSize.large ? PhotoSize.orig : PhotoSize.large)}/>
//             </div>
//         </Modal>
//     )
//     // } else 
//     //     return (<></>)
// }

// export function useImageFullscreen() {
//     const modal = useModal<string>()
//     return {
//         open: modal.openModal,
//         render: () => <ImageFullscreen modal={modal}/>
//     }
// }
export function useImageFullscreen() {
    const modal = useModal<[string, string[] | undefined]>()
    return {
        open: modal.openModal,
        render: () => 
            modal.modal && 
            <ImageFullscreen image={modal.modal[0]} items={modal.modal[1]} onClose={modal.closeModal} />
    }
}