import { Icon } from "./Icon"
import { Label } from "./Label"

interface CheckboxProps {
    left?: boolean
    disabled?: boolean
    radio?: boolean
    text?: string
    value: boolean
    className?: string
    style?:  React.CSSProperties
    setValue?: (next: boolean) => void
    onChange?: (e: React.MouseEvent, next: boolean) => void
}

export function Checkbox({ disabled, radio, value, setValue, text, left, className, style, onChange }: CheckboxProps) {
    function clickHandler(e: React.MouseEvent<HTMLDivElement>) {
        let next = !value
        setValue && setValue(next)
        onChange && onChange(e, next)
    }
    let icon;
    if (radio) {
        icon = value ? "radio_button_checked" : "radio_button_unchecked"
    } else {
        icon = value ? "check_box" : "check_box_outline_blank"
    }
    let useClassName = (className || "") + 
        " flex flex-row items-center gap-2 select-none " + (text == undefined ? "" : " py-2 ") + (disabled ? " text-disabled " : "")
    if (left)
        return (
            <div className={useClassName}
                style={style}
                onClick={clickHandler}>
                <Icon name={icon} className={"cursor-pointer "} />
                {text && <span>{text}</span>}
            </div>
        )
    else
        return (
            <div className={useClassName}
                style={style}
                onClick={clickHandler}>
                {text && <span>{text}</span>}
                <Icon name={icon} className={"cursor-pointer "} />
            </div>
        )
}

interface GroupBoxProps {
    text?: string
    horizontal?: boolean
    className?: string
    innerClassName?: string
    children: React.ReactNode
}

export function GroupBox({ text, horizontal, className, innerClassName, children }: GroupBoxProps) {
    return (
        <div className={"flex flex-col " + className}>
            {text &&
                <Label text={text} />
            }
            <div className={innerClassName + " border border-primary2 rounded px-4 pt-2 pb-2 relative " + (horizontal ? "flex flex-row justify-around" : "flex flex-col")}>
                {children}
            </div>
        </div>
    )
}