import { Checkbox } from "../../../comp/Checkbox"
import { ComboKeyText } from "../../../comp/Combobox"
import ContextMenuButton from "../../../comp/ContextMenuButton"
import { ToggleButton } from "../../../comp/Icon"
import { Label, LabelWith } from "../../../comp/Label"
import { IPoint, ImageAlign, ImageScale } from "../../../model/common"
import { DependsDataContext } from "../../../model/depends"
import { PhotoSize } from "../../../model/models"
import { ISlideImageField } from "../../../model/pptx"
import { imageBoxStyle } from "../boxes/ImageBox"

const IMAGE_SCALES: ComboKeyText<ImageScale>[] = [
    [ImageScale.auto, "None"],
    [ImageScale.cover, "Cover"],
    [ImageScale.contain, "Contain"],
    [ImageScale.fit, "Fit"],
]

interface EditImageTabProps {
    size: IPoint
    data: ISlideImageField
    context?: DependsDataContext
    isTemplate: boolean
    onChange: (nextData: ISlideImageField) => void
}

export function EditImageTab({ size, data, context, isTemplate, onChange }: EditImageTabProps) {
    function scaleHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        const scale = Object.values(ImageScale).find(sc => sc === e.target.value)
        onChange({ ...data, scale: scale || ImageScale.cover })
    }
    function setAlign(align: ImageAlign) {
        onChange({ ...data, align })
    }
    function setSrc(value: string) {
        onChange({ ...data, src: value })
    }
    // function selectImageHandler() {
    //     console.log(data)
    // }
    function customClickHandler(next: boolean) {
        if (next)
            onChange({ ...data, pos: { x: 0, y: 0 }, align: ImageAlign.custom })
        else
            onChange({ ...data, pos: undefined, align: ImageAlign.topLeft })
    }
    // console.log("Recompose edit image tab", data.align)
    let imgStyle = imageBoxStyle(data, PhotoSize.large)
    imgStyle.aspectRatio = size.x / size.y
    if (data.pos) {
        imgStyle.backgroundPosition = `${data.pos.x}% ${data.pos.y}%`
        // imgStyle.backgroundPosition = `${data.pos.x}px ${data.pos.y}px`
    }
    return (
        <div className="flex flex-col">
            <Label text="Image src" />
            <div className="flex flex-row w-full items-center gap-x-2">
                <input className="textfield flex-auto"
                    value={data.src} onChange={e => setSrc(e.target.value)} />
                {context &&
                    <ContextMenuButton icon="photo_library"
                        menu={context.toMenu(context.images)}
                        onClick={mi => setSrc(mi.id)} />
                }
                {/* <IconButton name="photo_library" disabled={!context}
                    onClick={selectImageHandler}/> */}
            </div>

            <div className="flex flex-row justify-around">
                <LabelWith text="Scale">
                    <select className="textfield" value={data.scale}
                        onChange={scaleHandler}>
                        {IMAGE_SCALES.map(item =>
                            <option value={item[0]} key={item[0]}>{item[1]}</option>
                        )}
                    </select>
                </LabelWith>

                <LabelWith text="Align">
                    <Checkbox text="Custom" className="pb-0" left
                        value={data.align === ImageAlign.custom} setValue={customClickHandler} />
                    {data.align === ImageAlign.custom
                        ? <div className="flex flex-col w1-[100px]">
                            <LabelWith text="X" className="pt-0 mt-0 w-[100px]" disabled={data.align !== ImageAlign.custom}>
                                <input className="textfield" type="number"
                                    value={data.pos?.x || 0}
                                    // setValue={next => onChange && onChange({...data, pos: { x: next, y: data.pos?.y || 0 }})}
                                    onChange={e => onChange && onChange({ ...data, pos: { x: Number.parseFloat(e.target.value), y: data.pos?.y || 0 } })}
                                    disabled={data.align !== ImageAlign.custom}></input>
                            </LabelWith>
                            <LabelWith text="Y" disabled={data.align !== ImageAlign.custom} className="w-[100px]">
                                <input className="textfield" type="number" value={data.pos?.y || 0}
                                    onChange={e => onChange && onChange({ ...data, pos: { x: data.pos?.x || 0, y: Number.parseFloat(e.target.value) } })}
                                    disabled={data.align !== ImageAlign.custom}></input>
                            </LabelWith>
                        </div>
                        : <div className="grid grid-cols-3 justify-center0 items-end0" >
                            <ToggleButton name="north_west"
                                onClick={() => setAlign(ImageAlign.topLeft)}
                                active={data.align === ImageAlign.topLeft} />
                            <ToggleButton name="north"
                                onClick={() => setAlign(ImageAlign.top)}
                                active={data.align === ImageAlign.top} />
                            <ToggleButton name="north_east"
                                onClick={() => setAlign(ImageAlign.topRight)}
                                active={data.align === ImageAlign.topRight} />

                            <ToggleButton name="west"
                                onClick={() => setAlign(ImageAlign.left)}
                                active={data.align === ImageAlign.left} />
                            <ToggleButton name="add"
                                onClick={() => setAlign(ImageAlign.center)}
                                active={data.align === ImageAlign.center} />
                            <ToggleButton name="east"
                                onClick={() => setAlign(ImageAlign.right)}
                                active={data.align === ImageAlign.right} />

                            <ToggleButton name="south_west"
                                onClick={() => setAlign(ImageAlign.bottomLeft)}
                                active={data.align === ImageAlign.bottomLeft} />
                            <ToggleButton name="south"
                                onClick={() => setAlign(ImageAlign.bottom)}
                                active={data.align === ImageAlign.bottom} />
                            <ToggleButton name="south_east"
                                onClick={() => setAlign(ImageAlign.bottomRight)}
                                active={data.align === ImageAlign.bottomRight} />
                        </div>
                    }
                    {/* </div> */}
                </LabelWith>
            </div>

            {data.src && !isTemplate &&
                <LabelWith text="Предпросмотр" >
                    <div style={imgStyle} className="w-full bg-primary2 drop-shadow" />
                </LabelWith>
            }
        </div>
    )
}