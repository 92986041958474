import { Loading } from "../../../comp/Loading";
import { useTocData } from "../../../hooks/useTocData";
import { dbid } from "../../../model/models";
import { TocData } from "../../../model/query";

export interface ITocDataLoaderProps {
    contestId: dbid
    onDone: (toc: TocData) => React.ReactElement
    onLoading?: () => React.ReactElement
    onError: () => React.ReactElement
}

export function TocDataLoader(props: ITocDataLoaderProps) {
    const { toc, loading } = useTocData(props.contestId);
    if (toc && !loading) {
        return props.onDone(toc);
    } else if (loading) {
        return props.onLoading ? props.onLoading() : <Loading />;
    } else {
        return props.onError();
    }
}
