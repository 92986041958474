import strings from "../strings"
import { AlignTextField, Color, IBackgroundField, IBorder, IPadding, IPoint, ImageAlign, ImageScale, VerticalAlignField } from "./common"
import { IContestEntity, IContestUser, INomination, TemplateFieldType, dbid } from "./models"
import { IDataIDs, IDataQuery, IDataScope, ITocItem, IVoteData, TYPE_CONTEST, TYPE_ENTITY, TYPE_NOMINATION, TYPE_VOTE, TocData, scopes } from "./query"
import { buildTemplateString } from "./templates"

// Slide size 960 на 540
export const SLIDE_SIZE = { x: 960, y: 540 } as IPoint

export enum GalleryLayoutKind {
    AUTO = "AUTO",
    BIG_CENTER_COL_RIGHT = "BIG_CENTER_COL_RIGHT",
    BIG_CENTER_COL_LEFT = "BIG_CENTER_COL_LEFT",
    GRID = "GRID",
    ROW = "ROW",
    COL = "COL"
}

export enum TextListKind {
    NUMBERED = "NUMBERED",
    BULLETED = "BULLETED",
    // DASHED = "DASHED"
    LAYOUT = "LAYOUT"
}

export interface IFieldTextStyle {
    fontSize?: number
    fontFamily?: string
    color?: Color
    background?: Color
    bold?: boolean
    italic?: boolean
    underline?: boolean
    strike?: boolean
}

export interface ISlideTextField {
    text: string
    align?: AlignTextField
    verticalAlign?: VerticalAlignField
    style?: IFieldTextStyle
    list?: TextListKind
}

export interface ISlideLayoutField {
    align: AlignTextField
    verticalAlign: VerticalAlignField
    maxX?: number
    maxY?: number
}

export interface ISlideImageField {
    src: string
    description?: string
    scale: ImageScale
    align?: ImageAlign
    pos?: IPoint
}

export interface ISlideGalleryField {
    items: string
    description?: string
    kind: GalleryLayoutKind
    gap?: number
    max?: number
}

export interface IVoteItem {
    id: string
    title: string
}

export interface ISlideVoteField {
    name: string
    items: IVoteItem[]
    outside?: boolean
    single?: boolean
    hideNames?: boolean
    align?: AlignTextField
    verticalAlign?: VerticalAlignField
}

export interface ISlideField {
    key: string
    pos: IPoint
    size: IPoint
    padding?: IPadding
    border?: IBorder
    background?: IBackgroundField

    text?: ISlideTextField
    image?: ISlideImageField
    gallery?: ISlideGalleryField
    vote?: ISlideVoteField
}

export function nameSlideField(field: ISlideField) {
    if (field.text)
        return "text"
    else if (field.image)
        return "image"
    else if (field.gallery)
        return "gallery"
    else if (field.vote)
        return "vote"
    else
        return "other"
}

export interface ISlideData {
    [index: string]: any | undefined
}

export enum ISlideBuildChildKind {
    slides = "slides",
    layout = "layout",
    none = "none",
}

export interface ISlideOptions {
    buildChildKind?: ISlideBuildChildKind
}

export interface ISlide {
    id: string
    name: string
    contestId?: dbid

    fields: ISlideField[]
    childs?: ISlide[]

    template?: string
    depends?: IDataIDs

    isTemplate?: boolean
    types?: string[]

    options?: ISlideOptions
}

export interface IPresentationOptions {
    // maxFiles?: number
}

export interface IPresentation {
    id: string
    contestId: dbid
    name: string
    description?: string
    created: number
    lastEdit?: number
    query: IDataQuery
    vote?: boolean
    voteStageId?: dbid

    slides: ISlide[]
    templates: ISlide[]

    options?: IPresentationOptions
}


export interface VotePlace<T> {
    itemId: string
    place: T
}
export type VotePlaceItems<T> = Array<VotePlace<T>>

///
///
///
export const FIELD_TYPE_TEXT = "text"
export const FIELD_TYPE_IMAGE = "image"
export const FIELD_TYPE_GALLERY = "gallery"
export const FIELD_TYPE_VOTE = "vote"
export const SLIDE_KEY_FIRST = "first"

function templateKey(type: string, nextNum: number) {
    return `${type}-${nextNum}`
}
function templateFieldKey(type: string, nextNum: number) {
    return `${type}-${nextNum}`
}
function titleField(index: number, text: string, fontSize?: number) {
    return {
        key: templateFieldKey(FIELD_TYPE_TEXT, index),
        pos: { x: 138, y: 30 },
        size: { x: 700, y: 60 },
        text: {
            text: text,
            align: "center",
            style: { fontSize: fontSize || 40 }
        }
    } as ISlideField
}
function galleryField(index: number, galleryField: string) {
    return {
        key: templateFieldKey(FIELD_TYPE_GALLERY, index),
        pos: { x: 10, y: 80 },
        size: { x: SLIDE_SIZE.x - 20, y: SLIDE_SIZE.y - 90 },
        gallery: {
            items: `{${galleryField}}`,
            kind: GalleryLayoutKind.AUTO
        } as ISlideGalleryField
    }
}

function imageField(index: number, imageField: string) {
    return {
        key: templateFieldKey(FIELD_TYPE_IMAGE, index),
        pos: { x: 10, y: 180 },
        size: { x: SLIDE_SIZE.x - 20, y: SLIDE_SIZE.y - 190 },
        image: {
            src: `{${imageField}}`
        } as ISlideImageField
    }
}

function createFirstSlideTemplate(nextNum: number) {
    return {
        id: templateKey(TYPE_CONTEST, nextNum),
        name: strings.slide_templ_first,
        fields: [titleField(0, "{contest.title}")],
        isTemplate: true,
        template: TYPE_CONTEST,
        types: [TYPE_CONTEST]
    } as ISlide
}

function createNominationSlideTemplate(nextNum: number) {
    return {
        id: templateKey(TYPE_NOMINATION, nextNum),
        name: strings.slide_templ_nomination,
        isTemplate: true,
        template: TYPE_NOMINATION,
        types: [TYPE_CONTEST, TYPE_NOMINATION],
        fields: [titleField(0, "{nomination.title}")]
    } as ISlide
}

function createEntitySlideTemplate(nextNum: number) {
    return {
        id: templateKey(TYPE_ENTITY, nextNum),
        name: strings.slide_templ_entity,
        fields: [titleField(0, "{entity.title}")],
        isTemplate: true,
        template: TYPE_ENTITY,
        types: [TYPE_CONTEST, TYPE_NOMINATION, TYPE_ENTITY]
    } as ISlide
}

export const VOTE_NAME = "{nomination}"

function createVoteSlideTemplate(nextNum: number) {
    let type = TYPE_VOTE
    return {
        id: templateKey(type, nextNum),
        name: strings.slide_templ_vote,
        fields: [
            titleField(0, "{nomination.title}", 25),
            {
                key: templateFieldKey(TYPE_VOTE, 1),
                pos: { x: 250, y: 110 },
                size: { x: 500, y: 410 },
                vote: {
                    name: VOTE_NAME,
                    items: []
                }
            } as ISlideField
        ],
        isTemplate: true,
        template: type,
        types: [TYPE_CONTEST, TYPE_NOMINATION]
    } as ISlide
}

function maxTemplatesNum(templates: ISlide[]) {
    let res = 0
    templates.forEach(slide => {
        let n = parseInt(slide.id.split("-")[1])
        if (n) {
            res = Math.max(res, n)
        }
    })
    return res
}

function buildVoteFieldFromTemplate(templVoteFld: ISlideVoteField | undefined, toc: TocData, depends: IDataIDs | undefined) {
    if (!templVoteFld || !depends)
        return
    let name = depends["nomination"] || ""
    let entitiesS = depends["entities"]
    let entityS = depends["entity"]
    let entities = entitiesS?.split("\n") || [entityS || ""]

    if (!name || (!entitiesS && !entityS))
        return
    // console.log("buildVoteFieldFromTemplate", templVoteFld, depends, entities)

    let items = Array<IVoteItem>()
    for (let id of entities) {
        let obj = toc.getById(id)
        if (obj?.title) {
            items.push({ id, title: obj.title })
        } else {
            console.error(`Unknown entity id \"${id}\"`)
        }
    }

    return {
        ...templVoteFld,
        name,
        items
    } as ISlideVoteField
}

var slideFieldKey = 0
export function nextSlideFieldKey(name: string, slideKey: string) {
    return `${name}-${slideFieldKey++}-${slideKey}-${Date.now()}`
}

export function buildTemplateField(slideKey: string, fld: ISlideField, toc: TocData, scope: IDataScope, depends: IDataIDs | undefined) {
    let fldName = nameSlideField(fld)
    return {
        ...fld,
        key: nextSlideFieldKey(fldName, slideKey),
        pos: { ...fld.pos },
        size: { ...fld.size },

        text: fld.text ? {
            ...fld.text,
            text: buildTemplateString(fld.text.text, toc, scope, depends),
        } as ISlideTextField : undefined,

        image: fld.image ? {
            ...fld.image,
            src: buildTemplateString(fld.image.src, toc, scope, depends),
            description: fld.image.description ? buildTemplateString(fld.image.description, toc, scope, depends) : undefined
        } as ISlideImageField : undefined,

        gallery: fld.gallery ? {
            ...fld.gallery,
            items: buildTemplateString(fld.gallery.items, toc, scope, depends),
            description: fld.gallery.description ? buildTemplateString(fld.gallery.description, toc, scope, depends) : undefined
        } as ISlideGalleryField : undefined,

        vote: buildVoteFieldFromTemplate(fld.vote, toc, depends)// ? {...fld.vote,
        // items: []//buildTemplateString(fld.vote.items, toc, depends)
        // } as ISlideVoteField : undefined
    } as ISlideField
}

export function buildTemplateFields(slideKey: string, flds: ISlideField[], toc: TocData, scope: IDataScope, depends: IDataIDs | undefined) {
    return flds.map(fld => buildTemplateField(slideKey, fld, toc, scope, depends))
}

export function buildSlideFromTemplate(
    slideKey: string, name: string, template: ISlide, toc: TocData, scope: IDataScope, depends: IDataIDs | undefined, childs?: ISlide[]
) {
    return {
        id: slideKey,
        fields: buildTemplateFields(slideKey, template.fields, toc, scope, depends),
        name: buildTemplateString(name, toc, scope, depends),
        template: template.id,
        depends,
        childs,
    } as ISlide
}

function getOrCreateSlide(type: string, templates: ISlide[], create: () => ISlide) {
    let slide = templates.find(slide => slide.template === type)
    if (slide)
        return slide
    slide = create()
    templates.push(slide)
    return slide
}

function buildSlidesDict(slides: ISlide[]): { [id: string]: ISlide | undefined } {
    let slidesDict: { [id: string]: ISlide | undefined } = {}
    function addSlides(childs: ISlide[]) {
        childs.forEach(slide => {
            if (slide.childs) {
                addSlides(slide.childs)
            } else if (!slide.template) {
                slidesDict[slide.id] = slide
            }
        })
    }
    addSlides(slides)
    return slidesDict
}

export function buildSlides(name: string,
    toc: TocData, voteData: IVoteData | undefined, query: IDataQuery,
    addVote: boolean,
    prevTemplates?: ISlide[],
    prevSlides?: ISlide[]
) {
    // console.log("VoteData", voteData)
    if (voteData)
        toc.setVoteResult(voteData)
    else
        toc.clearVoteResult()

    let slidesDict = prevSlides ? buildSlidesDict(prevSlides) : {}
    // console.log("slidesDict: ", slidesDict)
    // let nextSlides = prevSlides?.filter(slide => !slide.template && !slide.childs) || new Array<ISlide>()
    const nextSlides = new Array<ISlide>()
    let templates = prevTemplates || Array<ISlide>()
    let maxNum = prevTemplates ? maxTemplatesNum(prevTemplates) : 0

    let nominationSlideTemplate = getOrCreateSlide(TYPE_NOMINATION, templates, () =>
        createNominationSlideTemplate(maxNum++)
    )

    var voteSlideTemplate: ISlide | undefined
    if (addVote) {
        voteSlideTemplate = getOrCreateSlide(TYPE_VOTE, templates, () =>
            createVoteSlideTemplate(maxNum++)
        )
    }

    const contest = toc.contest

    let entitySlideTemplate = getOrCreateSlide(TYPE_ENTITY, templates, () => {
        let slide = createEntitySlideTemplate(maxNum++)
        if (contest.template?.fields) {
            let galleryFld = contest.template?.fields?.find(fld => fld.type === TemplateFieldType.IMAGES)
            let imageFld = contest.template?.fields?.find(fld => fld.type === TemplateFieldType.IMAGE)
            if (galleryFld) {
                slide.fields.push(galleryField(slide.fields.length, "entity.fields." + galleryFld.name))
            } else if (imageFld) {
                slide.fields.push(imageField(slide.fields.length, "entity.fields." + imageFld.name))
            }
        }
        return slide
    })

    let depends = {
        contest: scopes.fromContestId(contest.id),
    } as IDataIDs

    let firstSlide = slidesDict[SLIDE_KEY_FIRST]
    if (firstSlide) {
        nextSlides.push(firstSlide)
    } else if (nextSlides.length === 0) {
        let firstSlideTempl = createFirstSlideTemplate(maxNum++)
        firstSlide = buildSlideFromTemplate(SLIDE_KEY_FIRST, strings.slide_name_first, firstSlideTempl, toc,
            { title: name }, depends)
        firstSlide.template = undefined
        nextSlides.push(firstSlide)
    }
    var slideCount = nextSlides.length

    toc.filterQuery(query).forEach(sect => {
        const nomination = sect.nomination
        const entities = sect.filterQuery(query)
        let nomScope = {
            entitiesCount: entities.length,
            totalEntitiesCount: sect.items.length,
            entities: entities.map(e => e.entity),
        }
        let nomDepends = {
            ...depends,
            nomination: scopes.fromNominationId(nomination.id),
        }
        // console.log(nomScope);

        let nomSlideKey = scopes.fromNominationId(sect.nomination.id)
        let nomSlide = slidesDict[nomSlideKey] || buildSlideFromTemplate(nomSlideKey, nomination.title, nominationSlideTemplate,
            toc, nomScope, nomDepends)
        nextSlides.push(nomSlide)

        const count = entities.length
        slideCount += count + 1

        switch (nominationSlideTemplate.options?.buildChildKind) {
            case ISlideBuildChildKind.none:
                break;
            case ISlideBuildChildKind.layout:
                if (!slidesDict[nomSlideKey]) {
                    buildLayoutSlide(nomSlide, sect.nomination, entities, nominationSlideTemplate, entitySlideTemplate, toc, voteData, nomDepends);
                }
                break;
            case ISlideBuildChildKind.slides:
            default:
                // FIXME add empty slide option
                if (entities.length > 0) {
                    nextSlides.push({
                        id: nomSlideKey + ".nominees",
                        name: strings.nominees(entities.length),
                        fields: [],
                        childs: entities.map((entity, index) => {
                            let entitySlideKey = nomSlideKey + "." + scopes.fromEntityId(entity.id)
                            let entityScope = {
                                index: (entity.index + 1) + "",
                                number: index + 1 + "",
                                totalVotes: voteData ? ((entity.$votesValue || 0).toFixed(1) + "") : "",
                                totalPoints: voteData ? ((entity.$votesPoints || 0).toFixed(1) + "") : "",
                                totalVotesCount: voteData ? ((entity.$votesCount || 0) + "") : "",
                                votes: entity.$voteResult
                            }
                            let entitySlide = slidesDict[entitySlideKey] || buildSlideFromTemplate(entitySlideKey, entity.title, entitySlideTemplate,
                                toc, entityScope, {
                                ...nomDepends,
                                entity: entity.scopeId
                            })
                            return entitySlide
                        })
                    } as ISlide)
                }
                break;
        }

        if (voteSlideTemplate) {
            slideCount++
            let voteSlideKey = nomSlideKey + ".vote"
            let voteSlide = slidesDict[voteSlideKey] ||
                buildSlideFromTemplate(voteSlideKey, strings.slide_vote_title(nomination.title), voteSlideTemplate, toc,
                    {},
                    {
                        contest: scopes.fromContestId(contest.id),
                        nomination: scopes.fromNominationId(nomination.id),
                        entities: entities.map(entity =>
                            scopes.fromEntityId(entity.id)
                        ).join("\n")
                    })
            nextSlides.push(voteSlide)
        }
    })

    // console.log("Presentation templates", templates)
    // console.log("Presentation slides", nextSlides)

    return { templates, slides: nextSlides, count: slideCount }
}

function buildLayoutSlide(slide: ISlide, nomination: INomination, entities: ITocItem[], nominationSlideTemplate: ISlide, entitySlideTemplate: ISlide,
    toc: TocData, voteData: IVoteData | undefined, nomDepends: IDataIDs | undefined
) {
    let layout = nominationSlideTemplate.fields.find(fld => fld.text?.text === "{layout}");
    let layoutText = layout?.text;
    if (!layout || !layoutText) {
        return;
    }
    slide.fields = slide.fields.filter(e => e.text?.list !== TextListKind.LAYOUT);
    let itemW = 0;
    let itemH = 0
    let coeff = 1;
    entitySlideTemplate.fields.forEach((fld) => {
        itemW = Math.max(fld.pos.x + fld.size.x, itemW);
        itemH = Math.max(fld.pos.y + fld.size.y, itemH);
    })
    if (itemW === 0 || itemH === 0) {
        return;
    }
    let nomSlideKey = scopes.fromNominationId(nomination.id)

    let cntX = Math.max(Math.floor(layout.size.x / itemW), 1);
    let cntY = Math.ceil(entities.length / cntX);
    let maxCntY = Math.max(Math.floor(layout.size.y / itemH), 1);
    let maxCnt = cntX * maxCntY;
    console.log(`maxCnt ${maxCnt} total=${entities.length}`)
    if (entities.length > maxCnt) {
        let overflow = entities.length - maxCnt;
        coeff = calcCoeff(entities, layout.size, itemW, itemH);
        console.log(`overflow = ${overflow}, cntY=${cntY}, maxCntY=${maxCntY}, coeff=${coeff}`)
        itemW = itemW * coeff;
        itemH = itemH * coeff;
        cntX = Math.max(Math.floor(layout.size.x / itemW), 1);
        cntY = Math.ceil(entities.length / cntX);
    }

    let remainLine = cntX;
    let lineX = calcLineByX(entities.length, layout.size, itemW, layoutText.align);
    let posX = layout.pos.x + lineX.pos;
    let gapX = lineX.gap;
    let posY = layout.pos.y;
    let gapY = 0;

    if (layoutText.verticalAlign === VerticalAlignField.center) {
        gapY = Math.max((layout.size.y - cntY * itemH) / (cntY + 1), 0);
        posY = layout.pos.y + gapY;
    } else if (layoutText.verticalAlign === VerticalAlignField.end) {
        posY = layout.pos.y + layout.size.y - cntY * itemH;
    }

    for (var index = 0; index < entities.length; index++) {
        let entity = entities[index];
        if (remainLine < 1) {
            remainLine = cntX;
            let lineX = calcLineByX(entities.length - index, layout.size, itemW, layoutText.align);
            posX = layout.pos.x + lineX.pos;
            gapX = lineX.gap;
            posY += itemH + gapY;
        }
        let entitySlideKey = nomSlideKey + "." + scopes.fromEntityId(entity.id)
        let entityScope = {
            index: (entity.index + 1) + "",
            number: index + 1 + "",
            totalVotes: voteData ? ((entity.$votesValue || 0).toFixed(1) + "") : "",
            totalPoints: voteData ? ((entity.$votesPoints || 0).toFixed(1) + "") : "",
            totalVotesCount: voteData ? ((entity.$votesCount || 0) + "") : "",
            votes: entity.$voteResult
        }
        let fields = buildTemplateFields(entitySlideKey, entitySlideTemplate.fields, toc, entityScope, {
            ...nomDepends,
            entity: entity.scopeId
        })
        fields.forEach(fld => {
            fld.pos.x = fld.pos.x * coeff + posX;
            fld.pos.y = fld.pos.y * coeff + posY;
            if (coeff != 1) {
                fld.size.x = fld.size.x * coeff;
                fld.size.y = fld.size.y * coeff;
                let textStyle = fld.text?.style;
                if (textStyle && textStyle.fontSize) {
                    fld.text!.style = { ...textStyle, fontSize: textStyle.fontSize * coeff }
                }
            }
            slide.fields.push(fld);
        })
        posX += gapX + itemW;
        remainLine--;
    }
}

function calcCoeff(entities: ITocItem[], layoutSize: IPoint, itemW: number, itemH: number): number {
    let coeff = 1;
    let maxCnt;
    do {
        let cntX = Math.max(Math.floor(layoutSize.x / (itemW * coeff)), 1);
        // let cntY = Math.ceil(entities.length / cntX);
        let maxCntY = Math.max(Math.floor(layoutSize.y / (itemH * coeff)), 1);
        maxCnt = cntX * maxCntY;
        if (entities.length <= maxCnt) {
            return coeff;
        } else {
            coeff -= 0.05;
        }
    } while (entities.length > maxCnt);
    console.log(`maxCnt ${maxCnt} total=${entities.length}`)
    return coeff;
}

interface ILayoutRow<T> {
    posX: number
    posY: number
    coeff: number
    index: number
    items: T[]
}

export class LayoutRowsBuilder<T> {

    rows = new Array<ILayoutRow<T>>();
    cntX: number
    cntY: number
    maxCnt: number

    constructor(cntX: number, cntY: number) {
        this.cntX = cntX
        this.cntY = cntY
        this.maxCnt = cntX * cntY
    }

    normalize() {
        for (var i = this.rows.length - 2; i >= 0; i--) {
            let row = this.rows[i];
            if (row.items.length < this.cntX) {
                let nextRow = this.rows[i + 1];
                let el = nextRow.items.splice(0, 1)[0];
                if (el !== undefined) {
                    row.items.push(el);
                }
                return;
            }
        }
    }

    push(item: T, index: number) {
        let row = this.rows[this.rows.length - 1];
        if (!row || (row.items.length >= this.cntX && this.rows.length < this.cntY)) {
            row = { posX: 0, posY: 0, coeff: 1, index, items: [] };
            this.rows.push(row);
        }
        row.items.push(item);
        if (row.items.length > this.cntX) {
            this.normalize();
            if (row.items.length > this.cntX) {
                this.cntX++;
                // console.log(`overflow ${this.cntX - 1}=>${this.cntX}`)
            }
        }
    }

    pushAll(items: T[]) {
        items.forEach(this.push);
    }
}

function calcLineByX(cnt: number, size: IPoint, maxW: number, align: AlignTextField | undefined): { pos: number, gap: number } {
    let maxCntX = Math.max(Math.floor(size.x / maxW), 1);
    if (cnt > maxCntX)
        cnt = maxCntX;
    let pos = 0;
    let gap = 0
    if (align == AlignTextField.center) {
        pos = (size.x - cnt * maxW) / 2;
    } else if (align == AlignTextField.justify) {
        gap = Math.max((size.x - cnt * maxW) / (cnt + 1), 0);
        pos = gap;
    } else if (align == AlignTextField.right) {
        pos += size.x - cnt * maxW;
    }
    return { pos, gap };
}