import { AxiosError } from "axios";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorMessage } from "../../comp/ErrorMessage";
import { emailValidationNotStrict, titleValidation } from "../../comp/forms/validations";
import { LabelWith } from "../../comp/Label";
import { useOpenContests } from "../../hooks/useItems";
import { useAppDispatch } from "../../hooks/useTypedSelector";
import { dbid } from "../../model/models";
import { SubmitterService } from "../../services/SubmitterService";
import { submitterLoginAction } from "../../store/actions/auth";
import strings from "../../strings";
import { SubmitterLogin } from "../PublicStaticPage";
import { ILoginForm } from "./LoginTab";
import { useNavigate } from "react-router-dom";
import paths from "../../paths";

interface ISubmitterTabProps {
    title?: string
}

export function SubmitterTab({ }: ISubmitterTabProps) {
    const navigate = useNavigate()
    const [contest, setContest] = useState<[dbid, string | undefined]>()
    const [email, setEmail] = useState<string>()
    const [error, setError] = useState<string>()
    const dispatch = useAppDispatch()

    if (email && contest) {
        const onLoginSubmit: SubmitHandler<ILoginForm> = async (data) => {
            dispatch(submitterLoginAction(contest[0], data.username, data.password))
            // navigate(paths.submitter)
        }

        return <SubmitterLogin email={email}
            contestTitle={contest[1]}
            // buttonStyle={buttonStyle}
            onSubmit={onLoginSubmit} />
    } else {
        const onStartSubmit: SubmitHandler<ISubmitterStartForm> = async (data) => {
            try {
                const resp = await SubmitterService.start(data.contestId, data.email.trim())
                if (resp.statusText === "OK") {
                    setEmail(data.email);
                    setContest([data.contestId, data.contestTitle])
                }
            } catch (e) {
                console.log(e);
                // error(dispatch, e)
                if (e instanceof AxiosError) {
                    setError(e.response?.data?.message || e.response?.statusText || strings.err_server)
                }
            }
        }

        return <>
            <SubmitterStart
                // buttonStyle={buttonStyle}
                onSubmit={onStartSubmit} />
            {error && <ErrorMessage className="mt-2" message={error} />}
        </>
    }
}

export interface ISubmitterStartForm {
    contestId: dbid
    contestTitle?: string
    email: string
}

interface ISubmitterStrtProps {
    title?: string
    buttonStyle?: React.CSSProperties
    onSubmit: SubmitHandler<ISubmitterStartForm>
}

function SubmitterStart({ buttonStyle, onSubmit }: ISubmitterStrtProps) {
    const { register, formState: { errors }, watch, setValue, handleSubmit } = useForm<ISubmitterStartForm>({
        defaultValues: {} as ISubmitterStartForm
    })
    const contests = useOpenContests()
    const onPreSubmit: SubmitHandler<ISubmitterStartForm> = async (data) => {
        // console.log(data)
        data.contestTitle = contests.items.find(e => e.id === data.contestId)?.title
        onSubmit(data)
    }
    const watchContestId = watch("contestId")
    if (!watchContestId && contests.items.length > 0) {
        const contest = contests.items[0]
        setValue("contestId", contest.id)
        setValue("contestTitle", contest.title)
    }

    return (
        <form title="" className='flex flex-col space-y-2 mt-2 w-full'
            onSubmit={handleSubmit(onPreSubmit)}
        >
            <LabelWith text={strings.lab_contest} error={errors.contestId}>
                <select className='textinput'
                    placeholder={strings.lab_contest}
                    {...register("contestId", titleValidation())} >
                    {contests.items.map(e =>
                        <option key={e.id} value={e.id}>{e.title}</option>
                    )}
                </select>
            </LabelWith>

            <LabelWith text={strings.lab_email_to_submit} error={errors.email}>
                <input className='textinput'
                    placeholder={strings.lab_email}
                    {...register("email", emailValidationNotStrict(true))} />
            </LabelWith>

            <button type='submit' className='textbutton mt-4'
                style={buttonStyle}>
                {strings.button_confirm_email}
            </button>
        </form>
    )
}