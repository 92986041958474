import jsPDF from "jspdf";
import { GalleryLayoutKind, IPresentation, ISlide, ISlideField, ISlideGalleryField, ISlideImageField, ISlideTextField, SLIDE_SIZE } from "../model/pptx";
import { extractImageSrc } from "../comp/ImageFB";
import { PhotoSize } from "../model/models";
import { coverImgToCanvas, coverUri } from "../api/crop";
import { AlignTextField, IPoint, ImageAlign, VerticalAlignField } from "../model/common";
import { AllImagesLoader, ImgLoader, isInstanceOfVerticalLayout } from "../pages/EditPresentationPage/boxes/GalleryBox";
import { buildRects } from "./PptxBuilder";

const FONT_NAME = "Roboto";
const FONT_NAME_STYLE_NORMAL = "normal";
const FONT_NAME_STYLE_BOLD = "bold";
const FONT_NAME_STYLE_ITALIC = "italic";
const FONT_NAME_STYLE_BOLDITALIC = "bolditalic";

async function addImageBase(doc: jsPDF, src: string, posX: number, posY: number, w: number, h: number,
    align: ImageAlign | undefined, pos: IPoint | undefined
) {
    let uri = extractImageSrc(src, PhotoSize.large)
    let data = await coverUri(uri, w, h, { align, pos })
    // console.log(data)
    doc.addImage(data, "PNG", posX, posY, w, h)
}

// function addImageFromImg(doc: jsPDF, img: HTMLImageElement, posX: number, posY: number, w: number, h: number, 
//     align: ImageAlign | undefined, pos: IPoint | undefined
// ) {
//     let canvas = coverImgToCanvas(img, w, h, align || ImageAlign.center, pos)
//     if (canvas) 
//         doc.addImage(canvas.toDataURL(), "PNG", posX, posY, w, h)
// }

// function addImageFromLoader(doc: jsPDF, loader: ImgLoader, align?: ImageAlign) {
//     if (loader.fail)
//         return
//     addImageFromImg(doc, loader.img, loader.pos.x, loader.pos.y, loader.pos.w, loader.pos.h, align)
// }

async function addGallery(doc: jsPDF, field: ISlideField, gallery: ISlideGalleryField) {
    let poses = await buildRects(field, gallery)
    for (let pos of poses) {
        await addImageBase(doc, pos.src, pos.pos.x, pos.pos.y, pos.size.x, pos.size.y, undefined, undefined)
    }
    // let images = gallery.items.trim().split("\n")
    // if (images.length === 0) {
    //     return
    // }
    // if (images.length === 1) {
    //     return await addImageBase(doc, images[0], field.pos.x, field.pos.y, field.size.x, field.size.y)
    // }
    // let gap = gallery.gap ? gallery.gap : 0
    // let gap2 = gap / 2
    // let w_1_3 = field.size.x / 3
    // switch (gallery.kind) {
    //     case GalleryLayoutKind.AUTO: {
    //         let loader = new AllImagesLoader(images.slice(0, gallery.max || 5))
    //         let layouts = await loader.preloadAsync()
    //         console.log(layouts)
    //         loader.pos(layouts, field.pos, field.size, gallery.gap || 0)
    //         let index = 0
    //         for (let layout of layouts) {
    //             if (isInstanceOfVerticalLayout(layout)) {
    //                 layout.items.forEach( img => {
    //                     addImageFromLoader(doc, img)
    //                 })
    //             } else {
    //                 addImageFromLoader(doc, layout)
    //             }
    //             index++
    //         }
    //         return
    //     }
    //     case GalleryLayoutKind.BIG_CENTER_COL_RIGHT: {
    //         await addImageBase(doc, images[0], field.pos.x, field.pos.y, 2*w_1_3 - gap2, field.size.y)
    //         let cols = images.slice(1, 4)
    //         let hCol = field.size.y / cols.length
    //         let index = 0
    //         for (let img of cols) {
    //             await addImageBase(doc, img,
    //                 field.pos.x + 2*w_1_3 + gap2, 
    //                 field.pos.y + hCol*index + (index > 0 ? gap2 : 0), 
    //                 w_1_3 - gap, 
    //                 hCol - gap2                    
    //             )
    //             index ++
    //         }
    //         return
    //     }
    //     case GalleryLayoutKind.BIG_CENTER_COL_LEFT: {
    //         addImageBase(doc, images[0], field.pos.x + w_1_3 + gap2, field.pos.y, 2*w_1_3, field.size.y)
    //         let cols = images.slice(1, 4)
    //         let hCol = field.size.y / cols.length
    //         let index = 0
    //         for (let img of cols) {
    //             await addImageBase(doc, img,
    //                 field.pos.x, 
    //                 field.pos.y + hCol*index + (index > 0 ? gap2 : 0), 
    //                 w_1_3 - gap2, 
    //                 hCol - gap2)
    //             index++
    //         }
    //         return
    //     }
    //     case GalleryLayoutKind.GRID: {
    //         return
    //     }
    //     case GalleryLayoutKind.ROW: {
    //         return
    //     }
    //     case GalleryLayoutKind.COL: {
    //         return
    //     }
    // }
}

async function addText(doc: jsPDF, field: ISlideField, text: ISlideTextField) {
    let fontSize = text.style?.fontSize || 12
    doc.setFontSize(fontSize)

    if (text.style?.bold || text.style?.italic) {
        doc.setFont(FONT_NAME, text.style?.italic ? "italic" : "", text.style.bold ? "bold" : "")
    } else {
        doc.setFont(FONT_NAME)
    }

    let size = doc.getTextDimensions(text.text, { maxWidth: field.size.x })

    if (text.style?.color) {
        doc.setTextColor(text.style?.color)
    } else {
        doc.setTextColor("#000000")
    }
    if (text.style?.background) {
        doc.setFillColor(text.style?.background)
        doc.rect(field.pos.x, field.pos.y, field.size.x, field.size.y)
    } else {
        doc.setFillColor("")
    }

    let posX = field.pos.x
    let posY = field.pos.y
    if (text.align || text.verticalAlign) {
        if (text.align) {
            switch (text.align) {
                case AlignTextField.left:
                    break
                case AlignTextField.center:
                    // posX += (field.size.x - size.w) / 2
                    posX = posX + field.size.x / 2
                    break
                case AlignTextField.right:
                    posX += field.size.x - size.w
                    break
                case AlignTextField.justify:
                    break
            }
        }
        if (text.verticalAlign) {
            switch (text.verticalAlign) {
                case VerticalAlignField.start:
                    break
                case VerticalAlignField.center:
                    posY += (field.size.y - size.h) / 2
                    break
                case VerticalAlignField.end:
                    posY += field.size.y - size.h
                    break
            }
        }
    }
    // doc.setFillColor("#FFFFFF")
    // doc.rect(posX, posY, size.w, size.h)
    // doc.setFillColor("#FFFFFFFF")

    let arr = doc.splitTextToSize(text.text, size.w)
    doc.text(arr, posX, posY + fontSize, {
        align: text.align
    })
}

async function addText2(doc: jsPDF, field: ISlideField, text: ISlideTextField) {
    let style = `display: grid; font-size: ${text.style?.fontSize || 12}px; width: ${field.size.x}px; height: ${field.size.y}px;`
    let style2 = ""
    if (text.align) {
        style += `text-align: ${text.align}; `
        // style2 += `justify-self: ${text.align}; `
    }
    if (text.verticalAlign) {
        style2 += `align-self: ${text.verticalAlign}; `
    }
    if (text.style?.color) {
        style += `color: ${text.style?.color}; `
    }
    if (text.style?.background) {
        style += `background-color: ${text.style?.background}; `
    } //else 
    // style += `background-color: #FF0000; `
    if (text.style) {
        let decoration = text.style?.underline && text.style?.strike ? "underline line-through"
            : text.style?.underline ? "underline "
                : text.style?.strike ? "line-through"
                    : ""
        style += `text-decoration: ${decoration}; `
    }
    if (text.style?.italic) {
        style += `font-style: italic; `
    }
    if (text.style?.bold) {
        style += `font-weight: bold; `
    }
    let html = `<div style="${style}"><div style="${style2}">${text.text}</div></div>`
    await doc.html(html, {
        x: field.pos.x,
        y: field.pos.y,
        margin: 0
    })
}

async function addImage(doc: jsPDF, field: ISlideField, image: ISlideImageField) {
    await addImageBase(doc, image.src, field.pos.x, field.pos.y, field.size.x, field.size.y, image.align, image.pos)
}

async function addField(doc: jsPDF, field: ISlideField) {
    if (field.text) {
        await addText(doc, field, field.text)
    } else if (field.image) {
        await addImage(doc, field, field.image)
    } else if (field.gallery) {
        await addGallery(doc, field, field.gallery)
    }
}

async function addSlide(doc: jsPDF, slide: ISlide, first?: boolean) {
    if (slide.childs) {
        for (let child of slide.childs)
            await addSlide(doc, child)
    } else {
        // let page = first ? doc : 
        if (!first)
            doc.addPage()
        for (let field of slide.fields) {
            await addField(doc, field)
        }
    }
}

export async function buildPdf(presentation: IPresentation) {
    const doc = new jsPDF({
        orientation: "landscape",
        unit: "pt",
        format: [SLIDE_SIZE.x, SLIDE_SIZE.y]
    });
    doc.addFont("/font/Roboto-Regular.ttf", FONT_NAME, FONT_NAME_STYLE_NORMAL);
    doc.addFont("/font/Roboto-Bold.ttf", FONT_NAME, FONT_NAME_STYLE_BOLD);
    doc.addFont("/font/Roboto-Italic.ttf", FONT_NAME, FONT_NAME_STYLE_ITALIC);
    doc.addFont("/font/Roboto-BoldItalic.ttf", FONT_NAME, FONT_NAME_STYLE_BOLDITALIC);
    doc.setFont("Roboto");
    doc.setFontSize(12)
    // await addSlide(doc, presentation.slides[0], true)
    // await addSlide(doc, presentation.slides[1])
    let index = 0
    for (let slide of presentation.slides) {
        await addSlide(doc, slide, index === 0)
        index++
    }
    // doc.text("Привет! Hello world!", 10, 10);

    doc.save(`${presentation.name}.pdf`);
}