import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ResizeBox, ResizeBoxKind } from "../../../comp/ResizeBox";
import { BorderLineStyle, IBorder, IPoint } from "../../../model/common";
import { IVoteResult, IVoteRules, IVoteSubmit, dbid } from "../../../model/models";
import { ISlideField } from "../../../model/pptx";
import { TocData } from "../../../model/query";
import { SlideGalleryBox } from "./GalleryBox";
import { SlideImageBox } from "./ImageBox";
import { OnGotoSlideById, SlideVoteBox } from "./SlideVoteBox";
import { DEF_XLSX_FONT_SIZE, SlideTextBox } from "./TextBox";

export function borderCss(border: IBorder | undefined) {
    if (!border || !border.width)
        return
    const width = border.width
    const style = border.style || BorderLineStyle.solid
    const color = border.color || "black"
    return `${width}px ${style} ${color}`
}

interface SlideBoxProps {
    pos: IPoint
    size: IPoint
    field: ISlideField
    selected?: boolean
    onGoto?: OnGotoSlideById
    isTemplate: boolean
    onDblClick?: (field: ISlideField) => void
}
const MIN_FONT_SIZE = 10;

export function SlideBox({ pos, size, field, selected, onGoto, isTemplate, onDblClick }: SlideBoxProps) {
    const div = useRef<HTMLDivElement>(null)
    const [fieldText, setFieldText] = useState(field.text)
    useLayoutEffect(() => {
        let e = div.current
        if (e && field.text) {        
            let isEllipsis = e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
            if (isEllipsis && e.scrollHeight > 0 && e.scrollHeight > 0) {
                let coeff = (e.offsetHeight * e.offsetWidth) / (e.scrollHeight * e.scrollWidth)
                let fontSize = field.text.style?.fontSize || DEF_XLSX_FONT_SIZE
                if (fontSize > MIN_FONT_SIZE) {
                    fontSize = Math.max(fontSize*coeff, MIN_FONT_SIZE);
                    setFieldText({...field.text, style: {...(field.text.style || {}), fontSize}});
                }
            }
        }
    }, []);
    return (
        <div className="select-none absolute drop-shadow overflow-hidden grid"
            style={{
                left: `${pos.x}px`,
                top: `${pos.y}px`,
                width: `${size.x}px`,
                height: `${size.y}px`,
                paddingLeft: field.padding?.left,
                paddingTop: field.padding?.top,
                paddingRight: field.padding?.right,
                paddingBottom: field.padding?.bottom,
                border: borderCss(field.border),
            }}
            ref={div}
            onDoubleClick={() => onDblClick && onDblClick(field)}
        >
            {/* title={`pos: ${JSON.stringify(pos)}, size: ${JSON.stringify(size)}`} */}
            {fieldText
                ? <SlideTextBox data={fieldText} isTemplate />
                : field.image
                ? <SlideImageBox data={field.image} isTemplate={isTemplate} />
                : field.gallery
                ? <SlideGalleryBox field={field} data={field.gallery} isTemplate={isTemplate} />
                : field.vote
                ? <SlideVoteBox data={field.vote} isTemplate={isTemplate}
                    onGoto={onGoto} />
                : <></>
            }

            {selected &&
                <ResizeBox className="m-[1px] zIndex-50" kind={ResizeBoxKind.All} />
            }
        </div>
    )
}

