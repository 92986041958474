import { ErrorMessage } from "../../comp/ErrorMessage";
import { ListUseItems } from "../../comp/ListItems";
import { Modal } from "../../comp/Modal";
import { searchInFields } from "../../extends/string";
import { UseItems } from "../../hooks/useItems";
import { useModal } from "../../hooks/useModal";
import { IContest } from "../../model/models";
import { IDataQuery } from "../../model/query";
import { IDocumentHeader, IXlsxDoc } from "../../model/xlsx";
import DocService from "../../services/DocService";
import strings from "../../strings";
import { TocDataLoader } from "../EditPresentationPage/forms/TocDataLoader";
import { CreateTableForm } from "./CreateTableForm";
import { DocumentRow } from "./DocumentsView";

interface ITablesTabViewProps {
    title?: string
    search?: string
    contest: IContest
    useItems: UseItems<IDocumentHeader>
}

export function TablesTabView({ search, contest, useItems }: ITablesTabViewProps) {
    const createTable = useModal<IXlsxDoc>()

    async function submitTableHandler(table: IXlsxDoc) {
        createTable.closeModal();
        let created = Date.now()
        useItems.addItem({
            id: table.id, 
            name: table.name, 
            description: table.description,
            created, lastEdit: created
        } as IDocumentHeader)
    }
    async function createTableHandler() {
        createTable.openModal({ id: "", name: contest?.title, contestId: contest.id } as IXlsxDoc)
    }
    async function cloneTableHandler(item: IDocumentHeader) {
        try {
            let name = global.prompt(strings.prompt_enter_title, item.name)
            if (!name)
                return
            name = name.trim()
            if (useItems.items.find(item => item.name === name)) {
                global.alert(`Документ с имененм "${name}" уже существует`)
                return
            }
            let resp1 = await DocService.getTable(item.id)
            if (resp1.statusText !== "OK") {
                return;
            }
            let fromTable = resp1.data;
            let nextTable = {...fromTable, id: "", name };
            let resp2 = await DocService.createTable(nextTable);
            // const resp = await DocService.cloneTable(item.id, data)
            if (resp2.statusText === "OK")
                useItems.addItem(resp2.data, true)
        } catch (e) {
            console.error(e)
        }
    }
    async function deleteTableHandler(table: IDocumentHeader) {
        try {
            const resp = await DocService.deleteTable(table.id)
            if (resp.statusText === "OK") {
                useItems.removeItem(table)
            }
        } catch (e) {
            console.error(e)
        }
    }    
    return <>
        <div className="pt-4 pb-4 flex flex-row justify-end gap-2">
            {/* <button className="textbutton" disabled
                onClick={createImagesZipHandler}>{strings.mi_download_arch}</button> */}
            {/* <button className="textbutton"
                onClick={createPresentationHandler}>{strings.button_create_presentation}</button> */}
            <button className="textbutton"
                onClick={createTableHandler}>{strings.button_create_table}</button>
            {/* <button className="textbutton" 
                onClick={createSlideTemplateHandler}>{strings.button_create_slide_template}</button> */}
        </div>

        <ListUseItems
            search={search}
            useItems={useItems}
            onFilter={(items, s) =>
                items.filter(m => searchInFields(s, m.name, m.description))
            }
            render={item =>
                <DocumentRow key={item.id}
                    item={item} isPresentation={false}
                    onClone={cloneTableHandler}
                    onDelete={deleteTableHandler} />
            }
            nobottom />    
        {(contest && createTable.modal) &&
        <Modal title="Создать документ"
            onClose={createTable.closeModal}
            wide>
                <TocDataLoader contestId={contest.id} 
                    onDone={(toc) =>
                        <CreateTableForm
                            table={createTable.modal!}
                            toc={toc}
                            onClose={createTable.closeModal}
                            onSave={submitTableHandler} />
                    }
                    onError={() => 
                        <>
                        <ErrorMessage message={strings.err_load_data} />
                        <button className="errorbutton" 
                            onClick={createTable.closeModal}>{strings.button_close}</button>
                        </>
                    }
                    />
        </Modal>
        }
    </>;
}