import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { IconButton } from "../comp/Icon";
import { ListItems } from "../comp/ListItems";
import { Loading } from "../comp/Loading";
import ContestEntityRow from "../comp/rows/ContestEntityRow";
import { TabsView } from "../comp/TabsView";
import { EntitySortDir, useContestEntitiesAll, useNominationsAll } from "../hooks/useItems";
import { dbid, EntityState, IContest, IContestEntity, INomination, ITemplateField, TemplateFieldType } from "../model/models";
import strings from "../strings";
import { Checkbox } from "../comp/Checkbox";

interface IEntitiesStat {
    total: number
    approved: number
    rejected: number
    correction: number
    verification: number
    not_submited: number
}

function calcEntitiesStat(items: IContestEntity[]) {
    var res = { total: 0, approved: 0, rejected: 0, correction: 0, verification: 0, not_submited: 0 } as IEntitiesStat
    items.forEach(e => {
        res.total++
        switch (e.state) {
            case EntityState.APPROVED:
                res.approved++
                break;
            case EntityState.REJECTED:
                res.rejected++
                break;
            case EntityState.CORRECTION_REQUIRED:
                res.correction++
                break
            case EntityState.VERIFICATION:
                res.verification++
                break
            case EntityState.NOT_SUBMITTED:
                res.not_submited++
                break;
        }
    })
    return res
}

function useStatsFilter(items: IContestEntity[], filterStats: string[]) {
    const [filtered, setFiltered] = useState<IContestEntity[] | undefined>()
    function fetch() {
        // console.log("useStatsFilter refetch", items.length, filterStats)
        setFiltered(items.filter(e => filterStats.indexOf(e.state) >= 0))
    }
    useEffect(() => fetch(), [items.map(e => e.id).join(), filterStats.join()])
    return filtered
}

interface IStatisticsPageProps {
    contest: IContest
}

export function StatisticsPage({ contest }: IStatisticsPageProps) {
    const entities = useContestEntitiesAll(contest.id, EntitySortDir.CREATE_DESC)
    const [filterStats, setFilterStats] = useState<string[]>(() => [
        EntityState.APPROVED, EntityState.CORRECTION_REQUIRED, EntityState.VERIFICATION, EntityState.NOT_SUBMITTED, EntityState.REJECTED])
    const filtered = useStatsFilter(entities.items, filterStats)
    const [stats, setStats] = useState<IEntitiesStat>()
    useEffect(() => setStats(calcEntitiesStat(entities.items)), [contest.id, entities.items])
    if (entities.loading) {
        return <Loading />
    }

    return (
        <>
            <div>
                <div className="flex flex-row justify-end">
                    <IconButton name="refresh" onClick={entities.reload} />
                </div>
                {stats
                    ? <StatsView stats={
                        [
                            { key: EntityState.APPROVED, title: "Одобренных", count: stats.approved },
                            { key: EntityState.VERIFICATION, title: "На проверке", count: stats.verification },
                            { key: EntityState.CORRECTION_REQUIRED, title: "На исправлении", count: stats.correction },
                            { key: EntityState.NOT_SUBMITTED, title: "Ожидает", count: stats.not_submited },
                            { key: EntityState.REJECTED, title: "Отклоненных", count: stats.rejected },
                        ]}
                        total={{ key: "", title: "Общее кол-во заявок", count: stats.total }}
                        onChecked={e => setFilterStats(e)}
                    />
                    : <Loading />
                }
            </div>
            <TabsView>
                <div title="По номинациям">
                    <EntitiesByNominationsTab contestId={contest.id}
                        entities={filtered || entities.items} />
                </div>
                {contest.template?.fields?.filter(e => e.type == TemplateFieldType.ENUM)?.map(e =>
                    <div title={`По "${e.label}"`} key={e.name}>
                        <EntitisByField contest={contest}
                            entities={filtered || entities.items}
                            field={e} />
                    </div>
                )
                }
            </TabsView>
            {/*  */}
        </>
    );
}

interface IGroupEntities<T> {
    item: T | undefined
    items: IContestEntity[]
}

function useEntitisGroupByNominations(contestId: dbid, entities: IContestEntity[], nominations: INomination[]) {
    // const nominations = useNominationsAll(contestId)
    const [loading, setLoading] = useState<boolean>()
    const [sections, setSections] = useState<IGroupEntities<INomination>[]>()
    function fetchData() {
        // console.log("groupping", contestId, nominations)
        var none = { item: undefined, items: [] } as IGroupEntities<INomination>
        let map = new Map<string, IGroupEntities<INomination>>()
        var res: IGroupEntities<INomination>[] = []
        nominations.forEach(nom => {
            let sect = { item: nom, items: [] } as IGroupEntities<INomination>
            map.set(nom.id, sect)
            res.push(sect)
        })
        entities.forEach(e => {
            if (!e.nominationIds || e.nominationIds.length == 0) {
                none.items.push(e)
            } else {
                e.nominationIds?.forEach(nomId => {
                    let nom = nominations.find(e => e.id == nomId)
                    if (nom) {
                        let sect = map.get(nomId)
                        if (!sect) {
                            sect = { item: nom, items: [e] } as IGroupEntities<INomination>
                            map.set(nomId, sect)
                        } else {
                            sect.items.push(e)
                        }
                    }
                })
            }
        })
        // console.log(entities.length, nominations.length)
        // console.log(map)
        if (none.items.length > 0) {
            res.push(none)
        }
        // Array.from(map.values()).forEach(e => res.push(e))
        // res.sort((a,b) => b.items.length - a.items.length)
        setSections(res)
    }
    useEffect(() => fetchData(), [contestId, entities])

    return { sections, loading }
}

interface IEntitiesByNominationsProps {
    contestId: dbid
    entities: IContestEntity[]
    nominations: INomination[]
}

function EntitiesByNominations({ contestId, entities, nominations }: IEntitiesByNominationsProps) {
    const groups = useEntitisGroupByNominations(contestId, entities, nominations)
    if (groups.loading) {
        return <Loading />
    }

    return (
        <>
            {groups.sections && <>
                <StatsView stats={groups.sections.map((e, n) => {
                    return { key: e.item?.id || n.toString(), title: e.item?.title || strings.msg_unknown_field, count: e.items.length }
                })} nolegend />

                {groups.sections.map(sect =>
                    <GroupItemView key={sect.item?.id || "none"} contestId={contestId}
                        title={sect.item?.title || strings.msg_empty_entities}
                        items={sect.items} />
                )}
            </>
            }
        </>
    )
}

interface IEntitiesByNominationsTabProps {
    contestId: dbid
    entities: IContestEntity[]
}

function EntitiesByNominationsTab({ contestId, entities }: IEntitiesByNominationsTabProps) {
    const nominations = useNominationsAll(contestId)
    if (nominations.loading) {
        return <Loading />
    }
    // console.log("Loaded nominations", nominations.items)
    return <EntitiesByNominations contestId={contestId} entities={entities} nominations={nominations.items} />
}

function useEntitisGroupByField(contestId: dbid, entities: IContestEntity[], field: ITemplateField) {
    // const nominations = useNominationsAll(contestId)
    const [loading, setLoading] = useState<boolean>()
    const [sections, setSections] = useState<IGroupEntities<string>[]>()
    function fetchData() {
        var none = { item: undefined, items: [] } as IGroupEntities<string>
        let map = new Map<string, IGroupEntities<string>>()
        var res: IGroupEntities<string>[] = []
        field.enums?.forEach(e => {
            let sect = { item: e, items: [] } as IGroupEntities<string>
            map.set(e, sect)
            res.push(sect)
        })
        entities.forEach(e => {
            let value = e.fields[field.name]
            if (!value) {
                none.items.push(e)
            } else {
                let sect = map.get(value)
                if (!sect) {
                    sect = { item: value, items: [e] } as IGroupEntities<string>
                    map.set(value, sect)
                } else {
                    sect.items.push(e)
                }
            }
        })
        if (none.items.length > 0) {
            res.push(none)
        }
        res.sort((a, b) => b.items.length - a.items.length)
        setSections(res)
    }
    useEffect(() => fetchData(), [contestId, field])

    return { sections, loading }
}

interface IEntitisByFieldProps {
    contest: IContest
    entities: IContestEntity[]
    field: ITemplateField
}

function EntitisByField({ contest, entities, field }: IEntitisByFieldProps) {
    const groups = useEntitisGroupByField(contest.id, entities, field)

    return (
        <>
            {groups.sections && <>
                <StatsView stats={groups.sections.map(e => {
                    return { title: e.item || strings.msg_unknown_field, count: e.items.length } as StatsViewItem;
                })} />
                {groups.sections.map(sect =>
                    <GroupItemView key={sect.item || "none"} contestId={contest.id}
                        title={sect.item || strings.msg_unknown_field}
                        items={sect.items} />
                )}
            </>}
        </>
    )
}

interface IGroupItemProps {
    contestId: dbid
    title: string
    items: IContestEntity[]
}

function GroupItemView({ contestId, title, items }: IGroupItemProps) {
    const [opened, setOpened] = useState<boolean>(false)
    return (
        <div className="flex flex-col" >
            <div className="itemrow flex flex-col" onClick={e => setOpened(!opened)}>
                <div>{title}</div>
                <div className="self-end">Заявок: {items.length}</div>
            </div>
            {opened &&
                <ListItems items={items} className="bg-primary2" render={e =>
                    <ContestEntityRow key={e.id} contestId={contestId} item={e} />
                } />
            }
        </div>
    )
}

type StatsViewItem = { key: string, title: string, count: number }

interface IStatsViewProps {
    total?: StatsViewItem
    nolegend?: boolean
    stats: StatsViewItem[]
    onChecked?: (checkedKeys: string[]) => void
}

function StatsView({ total, stats, nolegend, onChecked }: IStatsViewProps) {
    const [checked, setChecked] = useState<string[]>(() => stats.map(e => e.key))
    const data = [
        ["Заявки", "Кол-во заявок"],
        ...stats.sort((a, b) => b.count - a.count).map(e => [e.title, e.count])
    ];

    const options = {
        sliceVisibilityThreshold: 0.02,
        backgroundColor: "#8E9AAF",
        // title: "My Daily Activities",
        legend: 'none',//{
        //     position: "right",
        //     alignment: "center",
        //     textStyle: {
        //       color: "#fff",
        //       fontFamily: "Montserrat, 'Segoe UI', Arial",
        //       fontSize: 14,
        //     },
        //   },
        // colors: ["#96A1B4", "#808EA5", "#5E6C84"]
        // colors: ["#808ea5", "#8399a5", "#a8c7a8", "#f5cf9e", "#f5b69e", "#f4a09f", "#b0b0b0"]
        colors: ["#808ea5", "#8294a5", "#8399a5", "#96b0a7", "#a8c7a8", "#cfcba3", "#f5cf9e", "#f5b69e", "#f4a09f", "#b0b0b0"]
    };
    function toggleChecked(key: string) {
        const prev = checked.indexOf(key) >= 0
        var next: string[]
        if (prev) {
            next = checked.filter(e => e !== key);
        } else {
            next = [...checked, key]
        }
        setChecked(next)
        onChecked && onChecked(next)
    }


    return (
        <div className="flex flex-row ">
            <Chart
                chartType="PieChart"
                data={data}
                options={options}
            />
            {!nolegend &&
                <div className="text-sm flex flex-col items-end flex-none justify-center max-w-[200px] overflow-hidden">
                    {total &&
                        <div className="flex flex-row">
                            <div className="truncate flex-auto">{total.title}</div>
                            <div className="flex-none">: {total.count}</div>
                        </div>
                    }
                    {stats.map(item =>
                        <div className="flex flex-row items-center" key={item.key}>
                            <div className="truncate flex-auto">{item.title}</div>
                            <div className="flex-none">: {item.count}</div>
                            {onChecked &&
                                <Checkbox key={item.key} className="ml-2"
                                    value={checked.indexOf(item.key) >= 0}
                                    setValue={e => toggleChecked(item.key)}
                                />
                            }
                        </div>
                    )}
                </div>
            }
        </div>
    );
}