import React, { useCallback } from "react";
import strings from "../strings";
import { useDropzone } from "react-dropzone";

interface ImgUploaderProps {
    className?: string
    buttonClassName?: string
    buttonStyle?: React.CSSProperties
    text?: string
    accept?: string
    multiple?: boolean
    onUpload: (file: FileList) => void
}

interface ImgUploaderState {
    preview?: string
    
}

export class ImgUploader extends React.Component<ImgUploaderProps, ImgUploaderState> {
    key = Date.now() + ""
    inputFile: React.RefObject<HTMLInputElement>;
    // state: ImgUploaderState = { };
    
    constructor(props: ImgUploaderProps) {
        super(props)
        this.inputFile = React.createRef()
    }

    changeFile(file: FileList) {
        this.key = Date.now() + ""
        this.props.onUpload(file)
    }

    changeListener(e: React.ChangeEvent<HTMLInputElement>) {
        // console.log("ImgUploader clickListener")
        if (!e.currentTarget.files)
            return
        this.changeFile(e.currentTarget.files)
    }

    buttonClickListener(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation()
        // e.preventDefault()
        // console.log("buttonClickListener")
        this.inputFile.current?.click()
    }
    handleDropFileChange(file: File) {
        // console.log("handleDropFileChange", file)
        // setFile(file);
    }

    render() {
        const accept = this.props.accept || "image/png, image/jpeg, image/jpg"
        const text = this.props.text || strings.button_load
        return (
            <div className={" " + (this.props.className || "")} 
                onClick={(e) => this.buttonClickListener(e)}
                >
                <input type="file" accept={accept} key={this.key} multiple={this.props.multiple}
                    className="hidden"
                    ref={this.inputFile}
                    onChange={(e) => this.changeListener(e)}
                    />
                
                <div className={this.props.buttonClassName || "textbutton"} 
                    style={this.props.buttonStyle}>{text}</div>
            </div>
        )
    }
}

// function MyDropzone() {
//     const onDrop = useCallback((acceptedFiles: File[]) => {
//         console.log("MyDropzone", acceptedFiles)
//       // Do something with the files
//     }, [])
//     const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  
//     return (
//       <div {...getRootProps()}>
//         <input {...getInputProps()} />
//         {
//           isDragActive 
//           ? <p>Drop the files here ...</p> 
//           : <p>Drag 'n' drop some files here, or click to select files</p>
//         }
//       </div>
//     )
//   }

// export class DocUploader extends React.Component<ImgUploaderProps, ImgUploaderState> {
//     inputFile: React.RefObject<HTMLInputElement>;
//     // state: ImgUploaderState = { };
    
//     constructor(props: ImgUploaderProps) {
//         super(props)
//         this.inputFile = React.createRef()
//     }

//     changeFile(file?: File) {
//         // const uploadFile = e.target.files && e.target.files[0]
//         if (file) {
//             this.props.onUpload(file)
//         }
//         // const img = URL.createObjectURL(file)
//         // setPreview(img)
//         // this.setState((prev) => ({
//         //     preview: img
//         // }));
//     }

//     render() {
//         //flex flex-col justify-center items-center
//         return (
//             <div className={" " + (this.props.className || "")}>
//                 <input type="file" accept="image/png, image/jpeg, image/jpg"
//                     className="hidden"
//                     ref={this.inputFile}
//                     onChange={(e) => e.currentTarget.files && this.changeFile(e.currentTarget.files[0])}
//                     />
//                 {/* { this.state.preview &&
//                 <img src={this.state.preview}/>
//                 } */}
//                 <button className="textbutton" onClick={(e) => { e.preventDefault(); this.inputFile.current?.click() } }>{strings.button_load}</button>
//             </div>
//         )
//     }
// }