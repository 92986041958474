import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackLink } from "../comp/BackLink";
import { ErrorMessage } from "../comp/ErrorMessage";
import { Loading } from "../comp/Loading";
import { useTocData, useVoteResult } from "../hooks/useTocData";
import { dbid } from "../model/models";
import { IXlsxDoc } from "../model/xlsx";
import paths from "../paths";
import DocService from "../services/DocService";
import strings from "../strings";
import { DocumentEditor } from "./EditPresentationPage/DocumentEditor";
import { KEY_DOC_TAB } from "./views/DocumentsView";

function useDocument(docId: dbid) {
    const [ loading, setLoading ] = useState<boolean>()
    const [ doc, setDoc ] = useState<IXlsxDoc>()
    const toc = useTocData(doc?.contestId || "")

    const voteData = useVoteResult(doc?.contestId || "", doc?.voteStageId || "")
    
    async function loadDoc() {
        console.log("Load document", !!doc)

        setLoading(true)
        try {
            const resp = await DocService.getTable(docId)
            setDoc(resp.data)
        } catch(e) {
            console.error(e)
        } finally {
            // console.log("Loading toc finish")
            setLoading(false)
        }
    }

    useEffect(() => {
        if (doc?.id !== docId)
            loadDoc()
    }, [docId])

    return { doc, setDoc, toc: toc.toc, voteData: voteData.data, loading: loading || toc.loading || voteData.loading }
}

export function EditDocumentPage() {
    const navigate = useNavigate()
    const docId = useParams().id || ""
    const { doc, setDoc, voteData, loading, toc } = useDocument(docId)

    function deletedDocumentHandler(_: dbid, contestId: dbid) {
        navigate(paths.contest_by_id(contestId, 2))
    }
    async function changeDocumentHandler(next: IXlsxDoc) {
        setDoc(next)
    }

    if (loading) {
        return (
            <Loading/>
        )
    } else if (doc && toc) {
        return (
            <div className="flex flex-col">
                <div className="maincontainer">
                    <BackLink to={paths.contest_by_id(doc.contestId, 2, `${KEY_DOC_TAB}=1`)}
                        text={strings.lab_documents}
                        current={`${strings.lab_table} - ${doc.name}`}/>
                </div>
                <DocumentEditor
                    document={doc} 
                    toc={toc} 
                    voteData={voteData}
                    onChanged={changeDocumentHandler}
                    onDeleted={deletedDocumentHandler}/>
            </div>
        )
    } else {
        return (
            <ErrorMessage message={strings.err_load_presentation}/>
        )
    }
}