import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../api";
import { Checkbox } from "../../comp/Checkbox";
import { ContextMenu, IContextMenuItem } from "../../comp/ContextMenu";
import { DescrView } from "../../comp/DescrView";
import { Icon } from "../../comp/Icon";
import { Label, LabelWith } from "../../comp/Label";
import { Loading } from "../../comp/Loading";
import { TabsView } from "../../comp/TabsView";
import { DependsDataContext } from "../../model/depends";
import { IVoteResult, dbid } from "../../model/models";
import { IPresentation, ISlide, ISlideBuildChildKind, buildSlides } from "../../model/pptx";
import { IDataQuery, IVoteData, TocData } from "../../model/query";
import paths from "../../paths";
import DocService from "../../services/DocService";
import { buildPdf } from "../../services/PdfBuilder";
import { buildPptx } from "../../services/PptxBuilder";
import strings from "../../strings";
import { DataQueryTocView } from "./DataQueryView";
import { SlideEditor } from "./SlideEditor";
import { SlideMini } from "./SlideMini";
import { Combobox } from "../../comp/Combobox";

const MENU_PPTX_LOCAL = "pptx_local"
const MENU_PPTX_SERVER = "pptx_server"
const MENU_PDF = "pdf"

const MENU_DOWNLOAD = [
    { id: MENU_PPTX_LOCAL, title: "PowerPoint (быстро)" } as IContextMenuItem,
    { id: MENU_PPTX_SERVER, title: "PowerPoint (медленно)" } as IContextMenuItem,
    { id: MENU_PDF, title: "PDF" } as IContextMenuItem,
]

function calcSlidesCount(slides: ISlide[]) {
    var res = 0

    for(let slide of slides) {
        if (slide.childs && slide.childs.length > 0) 
            res += calcSlidesCount(slide.childs)
        else res++
    }
    return res
}

interface PresentationStructProps {
    presentation: IPresentation
    toc: TocData
    voteData?: IVoteData
    onChanged?: (next: IPresentation) => void
    onDeleted?: (presId: dbid, contestId: dbid) => void
}

export function PresentationEditor({ presentation, toc, voteData, onChanged, onDeleted }: PresentationStructProps) {
    const navigate = useNavigate()
    const [saving, setSaving] = useState<boolean>()
    const [downloading, setDownloading] = useState<boolean>()
    const [query, setQuery] = useState(presentation.query)
    const [slides, setSlides] = useState<ISlide[]>(presentation.slides)
    const [templateSlides, setTemplateSlides] = useState<ISlide[]>(presentation.templates)
    const [slidesCount, setSlidesCount] = useState(() => calcSlidesCount(presentation.slides))
    const [selected, setSelected] = useState<{ slide: ISlide, child?: ISlide }>()
    const [context, setContext] = useState<DependsDataContext>()
    const [addVote, setAddVote] = useState<boolean>(presentation.vote || false)
    // console.log("PresentationEditor", presentation.id)
    function slideChangedHandler(nextSlide: ISlide) {
        if (!selected) return
        let slide = selected.child || selected.slide 
        slide.fields = nextSlide.fields;
        slide.options = nextSlide.options;
        let changed = slide.template !== nextSlide.template
        slide.template = nextSlide.template
        console.log("slideChangedHandler", nextSlide, slide);
        if (changed) {
            setSelected({...selected})
        }
    }
    function openTemplateHandler(slideKey: string) {
        let slide = templateSlides.find(slide => slide.id === slideKey)
        if (slide) 
            selectSlide(slide, undefined, true)
    }
    
    function rebuild(nextQuery: IDataQuery, nextAddVote: boolean) {
        console.log(toc)
        let res = buildSlides(presentation.name, toc, voteData, nextQuery, nextAddVote, templateSlides, slides)

        setSlides(res.slides)
        setTemplateSlides(res.templates)
        setSlidesCount(calcSlidesCount(res.slides))
    }
    function changeQueryHandler(nextQuery: IDataQuery) {
        setQuery(nextQuery)
        rebuild(nextQuery, addVote)
    }
    function addVoteSlideHandler(nextAddVote: boolean) {
        setAddVote(nextAddVote)
        rebuild(query, nextAddVote)
    }

    async function deleteHandler() {
        if (!global.confirm(strings.confirm_delete_presentation))
            return
        try {
            const resp = await DocService.deletePresentation(presentation.id)
            if (resp.statusText === "OK") {
                if (onDeleted) 
                    onDeleted(presentation.id, presentation.contestId)
                else    
                    navigate(paths.contest_by_id(presentation.contestId, 2))
            }
        } catch (e) {
            console.error(e)
        }     
    }

    async function downloadHandler(mi: IContextMenuItem) {
        setDownloading(true)
        try {
            switch (mi.id) {
                case MENU_PPTX_LOCAL:
                    await buildPptx({...presentation,
                        slides, templates: templateSlides
                    } as IPresentation)
                    break
                case MENU_PPTX_SERVER:
                    window.open(`${API_URL}/presentations/${presentation.id}/download`, '_blank')
                    break
                case MENU_PDF:
                    await buildPdf({...presentation,
                        slides, templates: templateSlides
                    } as IPresentation)
                    break        
            }
        } catch(e) {
            console.error(e)
        } finally {
            setDownloading(false)
        }
    }
    
    async function savePresentation() {
        if (!slides || !templateSlides)
            return

        const nextSlides = slides//.map( slide => 
        //     updatePresentationSlide(slide, templateSlides, toc)
        // )
        const next = {...presentation, query,
            slides: nextSlides, templates: templateSlides,
            vote: addVote
        } as IPresentation
        onChanged && onChanged(next)

        setSlides(nextSlides)
        if (selected) {
            let nextSelected = nextSlides.find(slide => slide.id === selected.slide.id)
            if (nextSelected) {
                let nextChild = selected.child ? nextSelected.childs?.find(slide => slide.id === selected.child?.id) : undefined
                selectSlide(nextSelected, nextChild, false)
            }
        }
        setSaving(true)
        try {
            const resp = await DocService.updatePresentation(next)
            console.log("Saving presentation...", resp.statusText)
        } catch (e) {
            console.error(e)
            // const err = e as AxiosError
            // const data: any = err.response?.data
            // setError("root", {
            //     type: "manual",
            //     message: data?.message || err.message
            // })
        } finally {
            setSaving(false)
        }
    }
    function voteStageHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        onChanged && onChanged({...presentation, voteStageId: e.target.value ? e.target.value : undefined })
    }
    function selectSlide(slide: ISlide, child: ISlide | undefined, isTemplate: boolean) {
        let o = { slide, child }

        let depends = ( o.child || o.slide ).depends
        let context: DependsDataContext | undefined
        if (depends) {
            context = new DependsDataContext(presentation.name, toc)
            context.depends(depends)
            if (voteData) {
                context.dependsVoteResult(voteData, !!depends.entity)
            }
        } else if (isTemplate && slide.types) {
            context = new DependsDataContext(presentation.name, toc)
            context.dependsType(slide.types)
            if (voteData) {
                context.dependsVoteResult(voteData, slide.types.indexOf("entity") >= 0)
            }
        }

        setSelected(o);
        setContext(context)
    }
    // console.log(presentation)
    return (
        <>
        <div className="flex flex-col maincontainer">
            {presentation.description && 
            <LabelWith text={strings.lab_descr}>
                <DescrView className="text-sm line-clamp-3 textfield" 
                    body={presentation.description}/>
            </LabelWith>
            }            
            <Label text={strings.lab_query}/>
            <DataQueryTocView className="bg-primary2 rounded"
                query={query} 
                toc={toc}
                addVote={!!presentation.voteStageId}
                onChange={changeQueryHandler} />
                
            {/* <div className="flex flex-row justify-between items-center">
                <Checkbox text="Данные голосования" left disabled={!onChanged}
                    value={document.vote || false} setValue={() => {}} 
                    onChange={addVoteHandler}/>
                <span className="text-xs text-disabled">{voteResult ? `${voteResult.length} проголосовавших членов жюри` : "нет данных о голосовании"}</span>
            </div> */}
            <LabelWith text="Добавить результаты голосования">
                <select className="textfield mx-4" 
                    value={presentation.voteStageId || ""}
                    onChange={voteStageHandler}
                    >
                    <option key={""} value={""}></option>
                    {toc.contest.stages.map(stg => 
                    <option key={stg.id} value={stg.id}>{stg.title}</option>
                    )}                                
                </select>
            </LabelWith>

            <span className="pt-4 pb-2">{strings.lab_slides_count(slidesCount)}</span>

            <Label text={strings.lab_actions}/>
            <div className="flex flex-row justify-center gap-2 relative p-1">
                <ButtonMenu onClick={downloadHandler} disabled={downloading}
                    menu={MENU_DOWNLOAD}
                    text={strings.mi_download}/>

                <button className="redbutton" onClick={deleteHandler} disabled={downloading}>
                    {strings.mi_delete}
                </button>

                <Link className="greenbutton" 
                    to={paths.show_presentation(presentation.id)} target="_blank" >
                    {strings.mi_show}                
                </Link>
                <button className="textbutton" onClick={() => rebuild(query, addVote)} disabled={downloading}>
                    {strings.button_rebuild}
                </button>
                <button className="textbutton" onClick={savePresentation} disabled={downloading}>
                    {strings.button_save_changes}
                </button>
                {(saving || downloading) &&
                // <div className="absolute z-10 bg-black/10 left-0 top-0 right-0 left-0 right-0 top-0 bottom-0" onClick={e => {e.stopPropagation(); e.preventDefault()}}>
                <Loading text={strings.msg_saving} className="absolute-y-center left-0"/>
                // </div>                
                }
            </div>
            <Checkbox className="pb-4 pt-4" left
                text={strings.lab_add_vote} 
                value={addVote} setValue={addVoteSlideHandler} />
            {/* <Checkbox className="pb-4 pt-4" left
                text="Добавить слайды заявок" 
                value={add} setValue={addEntitySlideHandler} /> */}
        </div>
        <TabsView className="w-screen md:maincontainer">
            <div title={strings.lab_slides}
                className="flex flex-row gap-x-2 p-2 w-full bg-primary2 overflow-x-scroll">

                {slides?.map( slide =>
                <SlideMini key={slide.id}
                    slide={slide} 
                    selected={selected?.slide === slide}
                    onClick={() => selectSlide(slide, slide.childs ? slide.childs[0] : undefined, false)}/>
                )}
            </div>
            <div title={strings.lab_slide_templates}
                className="flex flex-row gap-x-2 p-2 w-full bg-primary2 overflow-x-scroll">
                {templateSlides?.map( slide =>
                <SlideMini key={slide.id}
                    slide={slide} 
                    selected={selected?.slide === slide}
                    onClick={() => selectSlide(slide, slide.childs ? slide.childs[0] : undefined, true)}/>
                )}

            </div>
        </TabsView>        
        {selected &&
        <>
            <Label text={strings.lab_editor}/>
            <div className="flex flex-row items-center pl-4 py-2">
                <div className="w-1/2 flex flex-col">
                    <div className="">{selected.slide.name}</div>
                    {!selected.child && selected.slide.isTemplate && 
                    // <Combobox 
                    //     label="Создание дочерних слайдов"
                    //     value={selected.slide.options?.buildChildKind || ISlideBuildChildKind.slides}
                    //     items={[ISlideBuildChildKind.slides, ISlideBuildChildKind.layout]}
                    //     onChange={e => slideChangedHandler({...selected.slide, options: {...selected.slide.options, buildChildKind: e } })}
                    //     />
                    <LabelWith text="Создание дочерних слайдов">
                        <select className="textfield" value={selected.slide.options?.buildChildKind || ISlideBuildChildKind.slides}
                            onChange={e => {
                                slideChangedHandler({...selected.slide, options: {...selected.slide.options, buildChildKind: e.target.value === ISlideBuildChildKind.slides ? ISlideBuildChildKind.slides : ISlideBuildChildKind.layout } })
                            }}>
                            <option value={ISlideBuildChildKind.slides}>Слайдами</option>
                            <option value={ISlideBuildChildKind.layout}>В контрол</option>
                            <option value={ISlideBuildChildKind.none}>Нет</option>
                        </select>
                    </LabelWith>
                    }
                </div>
                {selected.slide.childs &&
                <select className="textfield w-1/2" value={selected.child?.id} 
                    onChange={e => 
                        selectSlide(selected.slide, selected.slide.childs?.find( ch => ch.id === e.target.value), false)
                    }>
                    {selected.slide.childs.map( child => 
                    <option value={child.id} key={child.id}>{child.name}</option>
                    )}
                </select>
                }
            </div>
            <div className="slidewrapper flex flex-col overflow-x-auto justify-center">
                <SlideEditor 
                    context={context}
                    slide={selected.child || selected.slide}
                    templates={templateSlides}
                    onChange={onChanged ? slideChangedHandler : undefined}
                    onOpen={onChanged ? openTemplateHandler : undefined}
                    />
            </div>
        </>
        }
        </>
    )
}

interface ButtonMenuProps {
    text: string
    menu: IContextMenuItem[]
    disabled?: boolean
    onClick: (mi: IContextMenuItem) => void
}

function ButtonMenu({ text, menu, disabled, onClick }: ButtonMenuProps) {
    const [showMenu, setShowMenu] = useState(false)
    return (
        <div>
        <button className="textbutton flex flex-row items-center gap-1" onClick={() => setShowMenu(prev => !prev)} disabled={disabled}>
            {text}
            <Icon name="arrow_drop_down" className="pt-1"/>
        </button>
        {showMenu &&
            <ContextMenu menu={menu}
                onClick={onClick}
                onClose={() => setShowMenu(false)}
                />
            }
        </div>
    )
}