import { useState } from "react"
import { TabsView } from "../../../comp/TabsView"
import { DependsDataContext } from "../../../model/depends"
import { ISlideField } from "../../../model/pptx"
import strings from "../../../strings"
import { EditBorderTab } from "./EditBorderTab"
import { EditGalleryTab } from "./EditGalleryTab"
import { EditImageTab } from "./EditImageTab"
import { EditPaddingTab } from "./EditPaddingTab"
import { EditTextFieldTab } from "./EditTextFieldTab"
import { EditVoteTab } from "./EditVoteTab"
import { Label } from "../../../comp/Label"
import { IPoint } from "../../../model/common"

interface EditFieldFormProps {
    field: ISlideField
    isTemplate: boolean
    context?: DependsDataContext
    onSubmit: (nextField: ISlideField) => void
    onDelete: (field: ISlideField) => void
    onBreakImages?: () => void
    onClose: () => void
}

export function EditFieldForm({ field, isTemplate, context, onClose, onSubmit, onDelete, onBreakImages }: EditFieldFormProps) {
    const [data, setData] = useState(field)

    function deleteHandler() {
        if (global.confirm(strings.confirm_remove_object)) {
            onDelete(field)
            onClose()
        }
    }
    function setPos(next: IPoint) {
        setData({...data, pos: next});
    }    
    function setSize(next: IPoint) {
        setData({...data, size: next});
    }

    function okHandler() {
        onSubmit(data)
        onClose()
    }

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row justify-around">
                <div className="flex flex-col">
                    <Label text="X" />
                    <input type="number" className="textfield w-[70px] "
                        value={data.pos.x}
                        onChange={e => setPos({...data.pos, x: parseInt(e.target.value) || data.pos.x })}
                    />
                </div>
                <div className="flex flex-col">
                    <Label text="Y" />
                    <input type="number" className="textfield w-[70px] "
                        value={data.pos.y}
                        onChange={e => setPos({...data.pos, y: parseInt(e.target.value) || data.pos.y })}
                    />
                </div>
                <div className="flex flex-col">
                    <Label text="Ширина" />
                    <input type="number" className="textfield w-[70px] "
                        value={data.size.x}
                        onChange={e => setSize({...data.size, x: parseInt(e.target.value) || data.size.x })}
                    />
                </div>
                <div className="flex flex-col">
                    <Label text="Высота" />
                    <input type="number" className="textfield w-[70px] "
                        value={data.size.y}
                        onChange={e => setSize({...data.size, y: parseInt(e.target.value) || data.size.y })}
                    />
                </div>
            </div>            
            <TabsView>
                { data.text && 
                <div title={strings.lab_edit_field_text}>
                    <EditTextFieldTab 
                        data={data.text} context={context}
                        onChange={nextData => setData({...data, text: nextData})}/>
                </div>
                }
                { data.image && 
                <div title={strings.lab_edit_field_image}>
                    <EditImageTab 
                        size={field.size} 
                        data={data.image} 
                        context={context}
                        isTemplate={isTemplate}
                        onChange={nextData => setData({...data, image: nextData})}/>
                </div>
                }
                { data.gallery &&
                <div title={strings.lab_edit_field_gallery}>
                    <EditGalleryTab 
                        data={data.gallery} isTemplate={isTemplate}
                        context={context}
                        onChange={nextData => setData({...data, gallery: nextData})}
                        onBreakImages={onBreakImages}
                        onClose={onClose}/>
                </div>
                }
                { data.vote &&
                <div title={strings.lab_edit_field_vote}>
                    <EditVoteTab 
                        data={data.vote} isTemplate={isTemplate}
                        context={context}
                        onChange={nextData => setData({...data, vote: nextData})}
                        onClose={onClose}/>
                </div>
                }
                <div title={strings.lab_edit_field_padding}>
                    <EditPaddingTab padding={data.padding} 
                        onChange={(next) => setData({...data, padding: next})}/>
                </div>
                <div title={strings.lab_edit_field_border} className="flex flex-col">
                    <EditBorderTab
                        border={data.border}
                        setBorder={(next) => setData({...data, border: next})}/>
                </div>
            </TabsView>

            <div className="flex flex-row justify-end gap-x-2 pt-4">
                <button className="redbutton" onClick={deleteHandler}>{strings.mi_delete}</button>
                <div className="flex-auto"></div>
                <button className="textbutton" onClick={onClose}>{strings.button_cancel}</button>
                <button className="textbutton" onClick={okHandler}>{strings.button_save}</button>
            </div>
        </div>
    )
}