import { AxiosError } from "axios";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorMessage } from "../../comp/ErrorMessage";
import { descrValidation, titleValidation } from "../../comp/forms/validations";
import { LabelWith } from "../../comp/Label";
import { IDataQuery, TocData } from "../../model/query";
import { buildRows, calcMaxColumns, IXlsxDoc } from "../../model/xlsx";
import DocService from "../../services/DocService";
import strings from "../../strings";
import { DataQueryTocView } from "../EditPresentationPage/DataQueryView";

interface ICreateCreateTableFormProps {
    table: IXlsxDoc
    toc: TocData
    onSave: (presentation: IXlsxDoc) => void
    onClose: () => void
}

export function CreateTableForm({ table, toc, onSave, onClose}: ICreateCreateTableFormProps) {
    const { register, formState: { errors }, handleSubmit, setError } = useForm<IXlsxDoc>({ defaultValues: table });
    const [query, setQuery] = useState<IDataQuery>(table.query || { nominationIds: [], entityQueries: [] });
    const [vote, setVote] = useState<boolean>(false);

    const onSubmit: SubmitHandler<IXlsxDoc> = async (data) => {
        let query = { nominationIds: [], entityQueries: [] } as IDataQuery;
        let rows = buildRows(toc, query, undefined, undefined, true, undefined);
        let table = {...data,
            id: Date.now() + "",
            contestId: toc.contest.id, 
            query, created: Date.now(),
            rows: rows.rows,
            templates: rows.templates,
            maxColumn: calcMaxColumns(rows.rows),
            header: true
        } as IXlsxDoc;

        try {
            const resp = await DocService.createTable(table)
            onSave({...table, id: resp.data.id })
            // onClose()
            // useItems.addItem(resp.data)
            // setCurrentXlsx(doc)
        } catch (e) {
            console.error(e)
            const err = e as AxiosError
            const data: any = err.response?.data
            setError("root", {
                type: "manual",
                message: data?.message || err.message
            })
        }
    }

    return (
        <form className='flex flex-col w-full' 
            onReset={onClose}
            onSubmit={handleSubmit(onSubmit)}>
            {errors.root?.message &&
            <ErrorMessage message={errors.root?.message}/>
            }
            <LabelWith text={strings.lab_title}
                error={errors.name}>
                <input className='textinput' 
                    placeholder={strings.lab_title}
                    {...register("name", titleValidation())} />
            </LabelWith>
            
            <LabelWith text={strings.lab_descr}
                error={errors.description}>
                <textarea className='textinput' 
                    placeholder={strings.lab_descr}
                    rows={5}
                    {...register("description", descrValidation())} />
            </LabelWith>

            <DataQueryTocView
                query={query}
                onChange={next => setQuery(next)}
                toc={toc}
                />

            <div className="flex flex-row pt-4 gap-2 justify-end">
                <button className="greenbutton" type="submit">{strings.button_add}</button>
                <button className="textbutton" type="reset">{strings.button_cancel}</button>
            </div>
        </form>
    )
}