import { Checkbox } from "./Checkbox"

interface IVotingPointsProps {
    className?: string
    textClassName?: string
    color?: string
    value: number
    onChange?: (next: number) => void
}

export function VotingPoints(props: IVotingPointsProps) {
    return (
        // <StarsPointsView {...props}/>
        <NumbersPointsView {...props}/>
    );
}

const numberPoints = Array.from({length: 10}, (_, i) => i + 1)

function NumbersPointsView({ className, textClassName, color, value, onChange }: IVotingPointsProps) {

    return (
        <div className={"flex flex-row justify-around select-none overflow-hidden0 w-full " + (className || "")}>
            {numberPoints.map(n => 
                <div key={n} className="flex flex-col items-center justify-center">
                    <div style={{color: color}}>{n}</div>
                    <Checkbox value={value === n} radio style={{color: color}} setValue={onChange && (() => onChange(n))}/>
                </div>
            )}    
        </div>
    )
}
// interface IStarsPointsViewProps {
//     className?: string
//     textClassName?: string
//     color?: string
//     value: number
//     onChange?: (next: number) => void
// }

function StarsPointsView({ className, textClassName, color, value, onChange }: IVotingPointsProps) {
    return (
        <div className={"flex flex-row items-center gap-x-[2px] select-none overflow-hidden " + (className || "")}>
            <StarView value={value} index={0} color={color} onChange={onChange} />
            <StarView value={value} index={1} color={color} onChange={onChange} />
            <StarView value={value} index={2} color={color} onChange={onChange} />
            <StarView value={value} index={3} color={color} onChange={onChange} />
            <StarView value={value} index={4} color={color} onChange={onChange} />
            <div className={"flex-1 " + (textClassName != null ? textClassName : "ml-4 text-lg")}>{value}</div>
        </div>
    );
}

interface IStarViewProps {
    value: number
    color?: string
    index?: number
    onChange?: (next: number) => void
}

function StarView({ value, index, color, onChange }: IStarViewProps) {
    const state = pointsValueToState(value, index || 0);
    function clickHandler(e: React.MouseEvent<HTMLImageElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation()
        if (!onChange) {
            return;
        }
        const pos = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - pos.left
        const p = x / pos.width
        console.log("StarView click", x, pos.width, p, pos, e)
        var addPoints;
        if (p > 0.6) {
            addPoints = 2;
        } else if (p > 0.2) {
            addPoints = 1;
        } else {
            addPoints = 0;
        }
        onChange((index || 0) * 2 + addPoints);
    }
    return (
        <div className="flex-1 flex-row justify-center items-center" onClick={clickHandler} >
            <StarSvg state={state} fill={color || "white"} />
        </div>
    )
}

interface IStarSvgProps {
    fill?: string
    state: StarState
}

function StarSvg({ state, fill }: IStarSvgProps) {
    var color = fill || "#5f6368"
    switch (state) {
        case StarState.NONE:
            return <svg xmlns="http://www.w3.org/2000/svg" fill={color} viewBox="80 -880 800 760">
                <path d="m323-245 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z">
                </path>
            </svg>
        case StarState.HALF:
            return <svg xmlns="http://www.w3.org/2000/svg" fill={color} viewBox="80 -880 800 760">
                <path d="m637-244-42-178 138-120-182-16-71-168v387l157 95ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z">
                </path>
            </svg>

        case StarState.FIL:
            return <svg xmlns="http://www.w3.org/2000/svg" fill={color} viewBox="80 -880 800 760">
                <path d="m233-120 65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z">
                </path>
            </svg>
    }
}

enum StarState {
    NONE = '0', HALF = '2', FIL = '1'
}

function pointsValueToState(points: number, index: number): StarState {
    const m = points - 2 * index;
    if (m > 1) {
        return StarState.FIL // fill star
    } else if (m > 0) {
        return StarState.HALF // half star
    } else {
        return StarState.NONE // empty star
    }
}
