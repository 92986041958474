import { useParams, useSearchParams } from "react-router-dom";
import { ITemplate, ITemplateField } from "../model/models";
import { Loading } from "../comp/Loading";
import { UserKind, useContest } from "../hooks/useItems";
import DataService from "../services/DataService";
import DocumentsView from "./views/DocumentsView";
import TemplateFieldsView from "./views/TemplateFieldsView";
import strings from "../strings";
import { UsersView } from "./views/UsersView";
import { NominationsView } from "./views/NominationsView";
import { EntitiesView } from "./views/EntitiesView";
import { useTitle } from "../hooks/useTitle";
import { JuryItemsView } from "./views/JuryItemsView";
import { SectionsView } from "../comp/SectionsView";
import { ErrorMessage } from "../comp/ErrorMessage";
import { ContestDescrPage } from "./views/ContestDescrPage";
import { VoteResultPage } from "./VoteResultPage";
import { StaticPagesView } from "./views/StaticPagesView";
import { StatisticsPage } from "./StatisticsPage";

function sortFields(fields: ITemplateField[] | undefined) {
    fields?.sort((a, b) =>
        (a.index === undefined ? fields.length : a.index) - (b.index === undefined ? fields.length : b.index)
    )
    fields?.forEach((m, index) => {
        m.index = index
    })
}
const KEY_TAB = "tab";

export default function ContestPage() {
    const contestId = useParams().id || "0"
    const [searchParams, setSearchParams] = useSearchParams()

    const { contest, setContest, loading, error } = useContest(contestId)
    useTitle(strings.lab_contest + (contest ? ` - ${contest.title}` : ""))

    const tabIndex = searchParams.get(KEY_TAB)
    const initIndex = (tabIndex && parseInt(tabIndex)) || 0

    async function saveTemplateFieldsHandler(nextFields: ITemplateField[]) {
        if (contest) {
            sortFields(nextFields)
            contest.template = { fields: nextFields } as ITemplate
            const resp = await DataService.saveContest(contest.id, contest)
            sortFields(resp.data.template?.fields)
            setContest(resp.data)
        }
    }

    function changeHandler(index: number) {
        setSearchParams((params) => {
            params.set(KEY_TAB, index + "");
            return params;
        });
    }

    return (
        <div className="maincontainer w-full flex flex-col">
            <h1 className="header">{strings.lab_contest}</h1>
            {contest && <div className="mt-4 mb-8 text-right text-2xl">«{contest?.title}»</div>}

            {contest
                ? <SectionsView initIndex={initIndex} onChange={changeHandler}>
                    <div title={strings.lab_descr}>
                        <ContestDescrPage
                            contestId={contestId}
                            contest={contest}
                            setContest={setContest} />
                    </div>

                    {contest.isAdmin &&
                        <div title={strings.lab_template_fields}>
                            <TemplateFieldsView
                                template={contest.template}
                                saveFields={saveTemplateFieldsHandler} />
                        </div>
                    }
                    {contest.isAdmin &&
                        <div title={strings.lab_documents}>
                            <DocumentsView
                                contestId={contestId}
                                contest={contest} />
                        </div>
                    }
                    {contest.isAdmin &&
                        <div title={strings.lab_admins}>
                            <UsersView
                                kind={UserKind.ADMINS}
                                contest={contest} />
                        </div>
                    }
                    {contest.isAdmin &&
                        <div title={strings.lab_users}>
                            <UsersView
                                kind={UserKind.USERS}
                                contest={contest} />
                        </div>
                    }
                    {contest.isAdmin &&
                        <div title={strings.lab_juries}>
                            <JuryItemsView contest={contest} />
                        </div>
                    }

                    <div title={`${strings.lab_nominations} ${contest.nominations}`}>
                        <NominationsView
                            contest={contest}
                            onAdd={() => contest.nominations = contest.nominations + 1} />
                    </div>

                    {contest.isAdmin &&
                        <div title={`${strings.lab_static_pages}`}>
                            <StaticPagesView contest={contest} />
                        </div>
                    }
                    <div title={`${strings.lab_contest_entities} ${contest.entities}`}>
                        <EntitiesView contest={contest} />
                    </div>
                    {contest.isAdmin &&
                        <div title="Статистика">
                            <StatisticsPage contest={contest} />
                        </div>
                    }
                    {contest.isAdmin &&
                        <div title={strings.lab_vote_result}>
                            <VoteResultPage contestId={contestId} />
                        </div>
                    }
                </SectionsView>
                : loading
                    ? <Loading />
                    : <ErrorMessage message={error || strings.err_load_contest} />
            }

        </div>
    );
}