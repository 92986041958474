import { useEffect, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { IContextMenuItem } from "../../comp/ContextMenu"
import ContextMenuButton from "../../comp/ContextMenuButton"
import { BottomLogoView } from "../../comp/LogoView"
import { TabsView } from "../../comp/TabsView"
import { TextInput, TextInputType } from "../../comp/TextInput"
import { usePresentations, useTables } from "../../hooks/useItems"
import menus from "../../menus"
import { IContest, dbid } from "../../model/models"
import { IDocumentHeader } from "../../model/xlsx"
import paths from "../../paths"
import strings from "../../strings"
import { PresentationsTabView } from "./PresentationsTabView"
import { TablesTabView } from "./TablesTabView"

export const KEY_DOC_TAB = "z";

interface DocumentsViewProps {
    contest: IContest
    contestId: dbid
}

export default function DocumentsView({ contest, contestId }: DocumentsViewProps) {
    const [searchParams, setSearchParams] = useSearchParams()
    const tabIndex = searchParams.get(KEY_DOC_TAB)
    const initIndex = (tabIndex && parseInt(tabIndex)) || 0

    useEffect(() => {
        searchParams.get(KEY_DOC_TAB) &&
            setSearchParams((params) => {
                params.delete(KEY_DOC_TAB)
                return params
            })        
    }, [tabIndex]);

    const [search, setSearch] = useState<string>("")
    const presentations = usePresentations(contestId)
    const tables = useTables(contestId)
    // function changeHandler(index: number) {
    //     setSearchParams((params) => {
    //         params.set(KEY_DOC_TAB, index + "");
    //         return params;
    //     });
    // }
    return (
        <div className="maincontainer">
            <div className="flex flex-row justify-end">
                <TextInput className="w-2/5"
                    type={TextInputType.text}
                    label={null} placeholder={strings.lab_search}
                    value={search} setValue={setSearch} />
            </div>
            <TabsView initIndex={initIndex} >
                <PresentationsTabView title={strings.lab_presentations} 
                    search={search}
                    contest={contest}
                    useItems={presentations}/>
                <TablesTabView title={strings.lab_tables} 
                    search={search} 
                    contest={contest}
                    useItems={tables}/>
            </TabsView>
            {/* <LabelWith text={strings.lab_slide_templates}>
                <ListUseItems 
                    useItems={slideTemplates}
                    nobottom
                    render={item => 
                        <RowWithName item={item} 
                            onClick={openSlideTemplate}
                            onDelete={deleteSlideTemplate}/>
                    }/>
            </LabelWith> */}
            <BottomLogoView />

        </div>
    )
}

type DocumentRowType = IDocumentHeader

const MENU_CLONE = "clone"
const MENU_DOCUMENT_ROW = [menus.delete,
{ id: MENU_CLONE, title: "Копировать" } as IContextMenuItem
]

interface DocumentRowProps {
    item: DocumentRowType
    isPresentation: boolean
    onDelete: (item: DocumentRowType) => void
    onClone?: (item: DocumentRowType) => void
}

export function DocumentRow({ item, isPresentation, onDelete, onClone }: DocumentRowProps) {

    let to = isPresentation ? paths.edit_presentation(item.id) : paths.edit_table(item.id)
    let text = item.name
    let date = new Date(item.lastEdit || item.created).toLocaleString()
    function menuClickHandler(mi: IContextMenuItem) {
        switch (mi.id) {
            case menus.DELETE:
                onDelete(item)
                break
            case MENU_CLONE:
                onClone && onClone(item)
                break
        }
    }
    return (
        <div className="itemrow flex flex-row gap-2 items-center" >

            <Link className="flex flex-col flex-auto" to={to}>
                <span>{text}</span>
                <span className="text-onPrimary/75 italic text-sm">{date}</span>
            </Link>

            <ContextMenuButton
                menu={MENU_DOCUMENT_ROW}
                // onVisible={(mi) => }
                onClick={menuClickHandler} />
        </div>
    )
}

