import { Link } from "react-router-dom"
import { IContestEntity, dbid } from "../../model/models"
import paths from "../../paths"

interface ContestEntityRowProps {
    contestId: dbid
    forJury?: boolean
    item: IContestEntity
}

export default function ContestEntityRow({ contestId, forJury, item }: ContestEntityRowProps) {
    const link = forJury
        ? paths.contest_entity_by_id_for_jury(item.id || "")
        : paths.contest_entity_by_id(contestId, item.id || "")

    return (
        <Link className="itemrow flex flex-col font-thin0"
            to={link}>
            <span className="text-lg">{item.title}</span>
            {!forJury &&
                <>
                    <span className="debugfield">{item.state}</span>
                    <div className="debugfield0 text-sm font-thin flex flex-row justify-end gap-x-2">
                        {/* <span>{strings.lab_created}: {item.created ? new Date(item.created).toLocaleDateString() : " - "}</span> */}
                        <span>{item.edited ? new Date(item.edited).toLocaleDateString() + ' ' + new Date(item.edited).toLocaleTimeString() : " - "}</span>
                    </div>
                    {/* <DescrView className="text-sm line-clamp-2" body={item.description} /> */}
                </>
            }
        </Link>
    )
}
