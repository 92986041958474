import React, { ReactElement } from "react";
import { useState } from "react";

interface TabViewProps {
    className?: string
    pagesClass?: string
    tabClass?: string
    activeClass?: string
    inactiveClass?: string
    initIndex?: number
    children?: React.ReactNode
    onChange?: (tab: number) => void
}

export interface ITab {
    title: string
    tabKey: any
    index: number
    child: ReactElement
}

const defaultTabClass = "py-4 border-0 w-full text-center cursor-default select-none flex justify-center items-center"
const defaultActiveClass = "border-b-4 font-bold"
const defaultInactiveClass = "border-b-2"

export function TabsView(props: TabViewProps) {
    const tabClass = props.tabClass || defaultTabClass
    const activeClass = props.activeClass || defaultActiveClass
    const inactiveClass = props.inactiveClass || defaultInactiveClass

    let tabs: ITab[] = []
    React.Children.forEach(props.children, (child, index) => {
        let el = child as ReactElement
        if (el) {
            let title = el.props.title || `Tab ${index + 1}`
            let tabKey = title//el.props.key || 
            tabs.push({ title: title, tabKey: tabKey, index: tabs.length, child: el })
        }
    })
    const startIndex = (props.initIndex && props.initIndex < tabs.length && props.initIndex) || 0
    const [index, setIndex] = useState(startIndex)

    function tabClickHanlder(tab: ITab) {
        setIndex(tab.index)
        props.onChange && props.onChange(tab.index);
    }

    const currentIndex = index < tabs.length ? index : startIndex
    return (
        <div className={(props.className || "") + ' flex flex-col'}>
            <div className='flex flex-row justify-around'>
                {tabs.map(tab =>
                    <div
                        key={tab.tabKey}
                        className={`${tabClass} ${tab.index === currentIndex ? activeClass : inactiveClass}`}
                        onClick={() => tabClickHanlder(tab)}
                    >{tab.title}</div>
                )}
            </div>
            <div className={props.pagesClass}>
                {tabs[currentIndex]?.child}
            </div>
        </div>
    )
}
