import { IDataIDs, IDataScope, TocData } from "./query"

const START_TOKEN = /(^|[^\\]){([^}]+)}/g

export function buildTemplateString(s: string, toc: TocData, scope: IDataScope, depends: IDataIDs | undefined): string {
    let res = s.replaceAll(START_TOKEN, m => {
        let n0 = m.indexOf("{")
        if (n0 < 0)
            return m
        let pref = m.substring(0, n0)
        let expr = m.substring(n0 + 1, m.length - 1).trim()
        
        return pref + fetchExpression(expr, toc, scope, depends)
    })
    // console.log("buildTemplateString", s, res);
    return res
}

export function fetchExpression(expr: string, toc: TocData, scope: IDataScope, depends: IDataIDs | undefined): string {
    let startExpr = expr;
    let index: number | undefined
    let prop: string | undefined
    let suff: string | undefined
    let n1 = expr.indexOf("[")
    if (n1 >= 0) {
        let n2 = expr.indexOf("]", n1)
        if (n2 >= 0) {
            prop = expr.substring(n1 + 1, n2)
            suff = expr.substring(n2 + 1)
            index = parseInt(prop)
            expr = expr.substring(0, n1)
        }
    }
    let res = toc.fetchExpr(expr, scope, depends)
    // console.log(`${startExpr} => ${expr} ${index}`, res)
    if (res) {
        if (index !== undefined && !Number.isNaN(index)) {
            if (typeof res === "string") {
                res = res.split("\n")[index]
            } else if (Array.isArray(res)) {
                res = res[index]
                // console.log(`Item ${startExpr} => ${expr} ${index} ${suff}`, res)
            }
        } else if (prop) {
            res = res[prop]
        }
        if (res && suff) {
            return fetchObj(res, suff);
        }
        return res
    } else 
        return ""
}

interface INextProp {
    prop: string
    index: number | undefined
    suff: string | undefined
}

function getNextProp(props: string): INextProp {
    if (props[0] === '[') {
        let n2 = props.indexOf("]", 1);
        if (n2 < 0) {
            return { prop: "", index: undefined, suff: undefined };
        }
        let prop = props.substring(1, n2);
        return { prop: prop, index: Number.parseInt(prop), suff: props.substring(n2 + 1) }
    } else {
        let n1 = props.indexOf('.', 1);
        let n2 = props.indexOf('[', 1);
        if (n1 < 0 && n2 < 0) {
            return { prop: props.substring(1), index: undefined, suff: undefined } 
        } else if (n1 > 0) {
            return { prop: props.substring(1, n1), index: undefined, suff: props.substring(n1) } 
        } else if (n2 > 0) {
            return { prop: props.substring(1, n2), index: undefined, suff: props.substring(n2) } 
        } else {
            return { prop: props.substring(1), index: undefined, suff: undefined }    
        }
    }
}

function fetchObj(o: any, props: string | undefined): any {
    if (!o || !props) {
        return o;
    }
    // console.log(`fetchObj ${props}`, o);
    if (props.startsWith(".")) {
        let prop = getNextProp(props);
        let o2 = o[prop.prop];
        // console.log(`fetchObj.prop`, o2, prop);
        return fetchObj(o2, prop.suff);
    } else if (props.startsWith("[")) {
        let prop = getNextProp(props);
        let o2;
        if (prop.index !== undefined && !Number.isNaN(prop.index)) {
            if (typeof o === "string") {
                o2 = o.split("\n")[prop.index]
            } else if (Array.isArray(o)) {
                o2 = o[prop.index]
            }
        } else if (prop) {
            o2 = o[prop.prop]
        }
        // console.log(`fetchObj.array`, o2, prop);
        return fetchObj(o2, prop.suff);
    } 
    return o;
}