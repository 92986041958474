import { useState } from "react"
import { useModal } from "../hooks/useModal"
import { Icon } from "./Icon"
import { Modal } from "./Modal"
import { HexColorPicker } from "react-colorful";
import strings from "../strings"
import { Color } from "../model/common";
import { Label, LabelWith } from "./Label";

interface ColorViewProps {
    color?: Color
    width?: number
    height?: number
    className?: string
    setColor?: (nextColor: Color) => void
}

export function ColorView({ color, setColor, width, height, className }: ColorViewProps) {
    const colorPicker = useModal<Color>()

    function changeColorHandler(nextColor: Color) {
        setColor && setColor(nextColor)
        colorPicker.closeModal()
    }
    return (
        <>
        <div className={"textfield gap-x-1 p-1 flex flex-row justify-center items-center " + (className || "")}
            style={{ width: width }}
            onClick={() => colorPicker.openModal(color || "black")}
            >
            <div style={{ background: color, width: width || 20, height: height || 20 }}
                className="rounded flex-auto min-w-[20px]"/>
            { setColor &&
            <Icon className="transparent" name="palette" />
            }
        </div>
        { colorPicker.modal && 
            <Modal title="Change color" onClose={colorPicker.closeModal}>
                <ColorPickerDialog 
                    color={colorPicker.modal}
                    onSubmit={changeColorHandler}
                    onClose={colorPicker.closeModal}/>
            </Modal>
        }
        </>
    )
}

interface ColorPickerDialogProps {
    color: Color
    onSubmit: (color: Color) => void
    onClose: () => void
}

function ColorPickerDialog({ color, onSubmit, onClose }: ColorPickerDialogProps) {
    const [ current, setCurrent ] = useState(color)
    return (
        <div className="flex flex-col justify-center items-center">
            <HexColorPicker color={current} onChange={setCurrent}/>
            <LabelWith text="Hex">
                <input className="textfield" type="text"
                    value={current}
                    onChange={e => setCurrent(e.target.value)}
                    />
            </LabelWith>
            <div className="flex flex-row pt-4 w-full justify-end">
                <button className="textbutton" onClick={onClose}>{strings.button_close}</button>
                <button className="textbutton" onClick={() => onSubmit(current)}>{strings.button_save}</button>
            </div>
        </div>
    )
}